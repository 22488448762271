import { CURRENT, FAIL, GETALLUSERS, GETONEUSER, LOGIN, LOGOUT, REGISTER } from "../ActionTypes/AuthTypes"
import axios from 'axios'
import { handleerror } from "./ErrorsActions"

export const register=(cordUser,navigate)=>async(dispatch)=>{
try {
   const res = await axios.post('/api/auth/SignUp', cordUser)

    dispatch(
        {

            type : REGISTER,
            payload : res.data

         }
        )
        navigate('/AllUser')


} catch (error) {
    // dispatch(
    //     {
    //     type : FAIL,
    //     payload : error.response.data.errors
    // } )
    error.response.data.errors.forEach(el=> dispatch(handleerror(el.msg)))

    
    
}
}

export const login=(cordUser,navigate)=>async(dispatch)=>{

    try {
        const res = await axios.post('/api/auth/SignIn',cordUser)

        dispatch(
            {
                type : LOGIN,
                payload : res.data

            }

        )
        navigate('/Profil')

    } catch (error) {
        // dispatch(
        //     {
        //         type : FAIL,
        //         payload : error.response.data.errors
        //     }
        // )
        error.response.data.errors.forEach(el=> dispatch(handleerror(el.msg)))
    }
}
export const logout=()=>{
    return(
        {
            type : LOGOUT
        }
    )
}
export const current=()=>async(dispatch)=>{
try {
    const config = {
        headers : {
            Authorised : localStorage.getItem('token')
        }
    }
   
    const res = await axios.get('/api/auth/CurrentUser',config)

    dispatch(
        {
            type : CURRENT,
            payload : res.data
         }
    )

} catch (error) {
     // dispatch(
     //   {
    //        type : FAIL,
     //      payload : error.response.data.errors
        //   })

        error.response.data.errors.array.forEach(element => {
            dispatch(handleerror(element.msg))
        });
    }

}

export const getAllusers=()=>async(dispatch)=>{

    try {
       const res =  await axios.get('/api/auth/AllUser')
    
        dispatch(
            {
                type : GETALLUSERS,
                payload : res.data.AllUser
    
            }
        )
    } catch (error) {
        console.log(error)
    }
        }

export const getOneUser=(id)=>async(dispatch)=>{

        try {
        const res =  await axios.get(`/api/auth/OneUser/${id}`)
            
         dispatch(
           {
            type : GETONEUSER,
             payload : res.data.OneUser
            
             }
                )
            } catch (error) {
                console.log(error)
            }
                }

        export const updateUser=(id,cordUser,navigate)=>async(dispatch)=>{
            try {
                 await axios.put(`/api/auth/UpdateUser/${id}`,cordUser)
                
                 dispatch(getAllusers())

                 navigate('/AllUser')
                }
            
            catch (error) {
                console.log(error)
            }
        }
        
        export const deleteUser = (id)=>async(dispatch)=>{
                try {
                    await axios.delete(`/api/auth/DeleteUser/${id}`)
                    dispatch(getAllusers())

                } catch (error) {
                    console.log(error)
                    
                }

        }

        // export const updateProfil=(id,cordUser,navigate)=>async(dispatch)=>{
        //     try {
        //          await axios.put(`/api/auth/UpdateUser/${id}`,cordUser)
                
        //          dispatch(current())

        //          navigate('/Profil')
        //         }
            
        //     catch (error) {
        //         console.log(error)
        //     }
        // }
        

        
        export const updateProfil = (id, cordUser, navigate) => async (dispatch) => {
            try {
              // Appel à l'API pour mettre à jour le profil
              const res = await axios.put(`/api/auth/UpdateUser/${id}`, cordUser);
          
              // Mettez à jour le token et l'utilisateur actuel (si le serveur retourne un nouveau token)
              const { token, user } = res.data; // Assurez-vous que le backend retourne ces données
              if (token) {
                localStorage.setItem('token', token);
              }
          
              dispatch(current()); // Met à jour le state utilisateur avec les nouvelles informations
              navigate('/Profil'); // Redirection vers la page Profil
            } catch (error) {
              console.error('Erreur lors de la mise à jour du profil :', error.response?.data?.message || error.message);
            }
          };