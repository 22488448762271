

import axios from "axios"
import { GETCHARGEEXP } from "../ActionTypes/ChargeTypes"



export const getchargexp = () => async (dispatch) => {
    try {
        
      const res = await axios.get('/api/ChargeExp/GetChargeExp')
            
      dispatch(
        {
        type: GETCHARGEEXP,
        payload: res.data.ChargeExpl
      });
    } catch (error) {
      console.log(error);
    }
  };

        export const AddChargeExp=(newChargeExp,navigate)=>async(dispatch)=>{
    
         try {
            
             await axios.post('/api/ChargeExp/AddChargeExp',newChargeExp,{
              headers : {
                  Authorised : localStorage.getItem('token')
              }
          })
      
             dispatch(getchargexp())
      
              navigate('/ListChargeExp')
           
         } catch (error) {
             console.log(error)
         }
      }

     