import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHR, updateHR, deleteHR } from '../Redux/Actions/HumainRessourcesActions';
import{updateSheet} from '../Redux/Actions/SheetActions';
import './HumanRessourcesList.css';

const HumanRessourcesList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHR());
  }, [dispatch]);

  const humanRessources = useSelector((state) => state.HumanRessourcesReducer.humanRessources);
  const mySheets = useSelector(state => state.SheetReducer.mySheets);

  const [editedHR, setEditedHR] = useState(null); // Emploi en cours d'édition
  const [updatedSchedule, setUpdatedSchedule] = useState({}); // Contenu modifié
  const [celluleCliquee, setCelluleCliquee] = useState({});
  const [datesRange, setDatesRange] = useState([]);
  const [startTime, setStartTime] = useState ();
  const [endTime, setEndTime] = useState ()

  const jours = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];
  const creneaux = [
    { label: "08h - 10h", heures: 2 },
    { label: "10h - 12h", heures: 2 },
    { label: "12h - 14h", heures: 2 },
    { label: "14h - 16h", heures: 2 },
    { label: "16h - 17h", heures: 1 },
    { label: "17h - 18h", heures: 1 },
    { label: "18h - 19h", heures: 1 },
    { label: "19h - 20h", heures: 1 },
    { label: "20h - 21h", heures: 1 },
    { label: "21h - 22h", heures: 1 },
    { label: "22h - 23h", heures: 1 }
  ];
  
  const calculateWeekDates = (startDate) => {
    if (!startDate) return [];
    const start = new Date(startDate);
    const end = new Date(start);
    end.setDate(start.getDate() + 6);

    const formattedStart = `${String(start.getDate()).padStart(2, '0')}/${String(start.getMonth() + 1).padStart(2, '0')}/${start.getFullYear()}`;
    const formattedEnd = `${String(end.getDate()).padStart(2, '0')}/${String(end.getMonth() + 1).padStart(2, '0')}/${end.getFullYear()}`;

    return { formattedStart, formattedEnd };
  };

  const startEditing = (resource, sheet) => {
    setEditedHR(resource, sheet); // Définir l'emploi en cours d'édition
    setUpdatedSchedule({ ...resource.emploisDuTemps, ...sheet.debut,...sheet.fin }); // Copier son emploi du temps
  };

  const saveChanges = () => {
    if (editedHR) {
       
      console.log('Contenu modifié à enregistrer:', updatedSchedule); // Ajoutez ceci
      dispatch(updateHR(editedHR._id, { emploisDuTemps: updatedSchedule, celluleCliquee, datesRange }));
      dispatch(updateSheet(editedHR._id, { debut: updatedSchedule, fin: updatedSchedule }));
      setEditedHR(null);
      setUpdatedSchedule({});
      setCelluleCliquee()
      setDatesRange([])
      setStartTime()
      setEndTime()
      
      
    }
  };
  

  const cancelEditing = () => {
    setEditedHR(null); // Annuler l'édition
    setUpdatedSchedule({});
  };

  const deleteSchedule = (id) => {
    dispatch(deleteHR(id)); // Supprimer l'emploi
  };

  const toggleSchedule = (cellKey) => {
    setUpdatedSchedule((prev) => ({
      ...prev,
      [cellKey]: !prev[cellKey], // Basculer l'état actif/inactif
    }));
  };

  return (
    <div className="human-resources-container">
      <h3>Gestion des Emplois du Temps</h3>
      {humanRessources && humanRessources.map((resource, i) => {
        // Extraire la date de début associée à l'emploi du temps
        const debut = resource.debut; // Supposons que `debut` est la date stockée dans la ressource
        const { formattedStart, formattedEnd } = calculateWeekDates(debut);

        return (

        <div key={i}>
          <h4>Emploi du Temps pour {resource.owner?.name || 'Employé Inconnu'}</h4>
          {/* Affichage de l'intervalle de dates */}
          <div className="emploi-semaine">
              <span>
                Emploi de la semaine du : {formattedStart} au {formattedEnd}
              </span>
            </div>
          
          
          <table>
            <thead>
              <tr>
                <th>Jour</th>
                {creneaux.map((creneau, index) => (
                  <th key={index}>{creneau.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {jours.map((jour, jourIndex) => (
                <tr key={jourIndex}>
                  <td>{jour}</td>
                  {creneaux.map((_, creneauIndex) => {
                    const cellKey = `${resource.owner?._id}-${jourIndex}-${creneauIndex}`;
                    const isActive = editedHR
                      ? updatedSchedule[cellKey]
                      : resource.emploisDuTemps?.[cellKey];

                    return (
                      <td
                        key={creneauIndex}
                        className={isActive ? 'cellule-active' : ''}
                        style={{
                          backgroundColor: isActive ? "red" : "white",
                          cursor: editedHR ? 'pointer' : 'default',
                        }}
                        onClick={() => {
                          if (editedHR) toggleSchedule(cellKey);
                        }}
                      >
                        {isActive ? "✓" : ""}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
          <div className="d-flex justify-content-between mb-3">
  <button className="btn btn-secondary" onClick={() => startEditing(resource)}>
    Modifier
  </button>
  <button className="btn btn-danger" onClick={() => deleteSchedule(resource._id)}>
    Supprimer
  </button>
</div>
{editedHR && editedHR._id === resource._id && (
  <div className="d-flex justify-content-center gap-2">
    <button className="btn btn-primary" onClick={saveChanges}>
      Enregistrer
    </button>
    <button className="btn btn-primary" onClick={cancelEditing}>
      Annuler
    </button>
  </div>
          )}
        </div>
      )})}
    </div>
  );
};

export default HumanRessourcesList;
