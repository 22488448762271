import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMyHR } from '../Redux/Actions/HumainRessourcesActions';
import { current } from '../Redux/Actions/AuthActions';
import { getMySheets } from '../Redux/Actions/SheetActions';
import { Button } from "react-bootstrap";
import ValidEntree from './ValidEntree';
import ValidSortie from './ValidSortie';
import { updateSheetInfo } from '../Redux/Actions/SheetActions';
import './MyEmploi.css';

const MyEmploi = () => {
    const dispatch = useDispatch();
    const [dateNow, setDateNow] = useState({});
    const [tempsEcoule, setTempsEcoule] = useState("00:00:00");
    const [salaireJournalier, setSalaireJournalier] = useState(0);
    const [mySheet,setMySheet] = useState({})
    
    const user = useSelector((state) => state.AuthReducer.user);

    useEffect(() => {
        dispatch(current());
    }, [dispatch]);

    useEffect(() => {
        if (user?._id) {
            dispatch(getMyHR(user._id));
            dispatch(getMySheets(user._id));
            setDateNow(getNow());
        }
    }, [user, dispatch]);


    const myHumanRessources = useSelector(state => state.HumanRessourcesReducer.myHumanRessources);
    const mySheets = useSelector(state => state.SheetReducer.mySheets);

    useEffect(() => {
       setMySheet(mySheets
        .find((el) => dateNow.formattedDate === el.date && el.debut <= dateNow.formattedTime && el.fin >= dateNow.formattedTime))
    }, [mySheets]);
    
    const jours = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];
    const creneaux = [
        { label: "08h - 10h", heures: 2 },
        { label: "10h - 12h", heures: 2 },
        { label: "12h - 14h", heures: 2 },
        { label: "14h - 16h", heures: 2 },
        { label: "16h - 17h", heures: 1 },
        { label: "17h - 18h", heures: 1 },
        { label: "18h - 19h", heures: 1 },
        { label: "19h - 20h", heures: 1 },
        { label: "20h - 21h", heures: 1 },
        { label: "21h - 22h", heures: 1 },
        { label: "22h - 23h", heures: 1 }
    ];

    const calculateWeekDates = (startDate) => {
        if (!startDate) return [];
        const start = new Date(startDate);
        const end = new Date(start);
        end.setDate(start.getDate() + 6);
    
        const formattedStart = `${String(start.getDate()).padStart(2, '0')}/${String(start.getMonth() + 1).padStart(2, '0')}/${start.getFullYear()}`;
        const formattedEnd = `${String(end.getDate()).padStart(2, '0')}/${String(end.getMonth() + 1).padStart(2, '0')}/${end.getFullYear()}`;
    
        return { formattedStart, formattedEnd };
      };

    const salaireMensuel = myHumanRessources.salaireMensuel || 0;

       const getNow = () => {
        const currentTime = new Date();
        const hours = String(currentTime.getHours()).padStart(2, '0');
        const minutes = String(currentTime.getMinutes()).padStart(2, '0');
        const day = String(currentTime.getDate()).padStart(2, '0');
        const month = String(currentTime.getMonth() + 1).padStart(2, '0');
        const year = currentTime.getFullYear();

        return {
            formattedTime: `${hours}:${minutes}`,
            formattedDate: `${day}/${month}/${year}`
        };
    };
    const targetPositions = {
        userspintoss: { lat: 35.028992, lon: 9.486336 },
        usertwinpark: { lat: 35.029492, lon: 9.486836 },
        userwellness: { lat: 35.030992, lon: 9.487336 },
        useradmin: { lat: 36.8064948, lon: 10.1815316 },
        admin : { lat: 36.8064948, lon: 10.1815316 }
    };


    const calculateWorkTimeAndDelay = (debut, entree, fin, sortie) => {
        const parseTime = (time) => {
            if (!time) return null;
            const [hours, minutes] = time.split(':').map(Number);
            return hours * 60 + minutes; // Conversion en minutes
        };
    
        const startTime = parseTime(debut);
        const entryTime = parseTime(entree);
        const endTime = parseTime(fin);
        const exitTime = parseTime(sortie);
    
        if (startTime === null || entryTime === null || endTime === null || exitTime === null) {
            return {
                workedHours: 0,
                workedMinutes: 0,
                delayHours: 0,
                delayMinutes: 0,
                penaltyDT: 0
            };
        }
    
        // Calcul des heures de travail avec la nouvelle règle
        // Heures de travail = Heure supérieure entre entrée et début et heure inférieure entre fin et sortie
        const workStartTime = Math.max(entryTime, startTime);  // Heure supérieure entre entrée et début
        const workEndTime = Math.min(exitTime, endTime);       // Heure inférieure entre fin et sortie
    
        // Calcul des minutes travaillées
        const workedMinutes = Math.max(0, workEndTime - workStartTime);
        const delayMinutes = Math.max(0, entryTime - startTime) + Math.max(0, endTime - exitTime);

        // Calcul de la pénalité horaire en DT
        
        const hourlyWage = salaireMensuel /208;
            let penaltyDT = 0;

        if (delayMinutes > 15) {
          penaltyDT = delayMinutes > 60 ? 4 * hourlyWage : 1*hourlyWage;
        }

        return {
            workedHours: Math.floor(workedMinutes / 60),
            workedMinutes: workedMinutes % 60,
            delayHours: Math.floor(delayMinutes / 60),
            delayMinutes: delayMinutes % 60,
            penaltyDT
        };
    };
    useEffect(() => {
        // Fonction pour calculer le temps écoulé
        const calculerTempsEcoule = () => {
          const maintenant = new Date();
          const [heure, minute] = mySheet?.entree?.split(':').map(Number);
          const debut = new Date();
          debut.setHours(heure, minute, 0, 0);
    
          const ecouleMs = maintenant - debut;
          const heures = Math.floor((ecouleMs / (1000 * 60 * 60)) % 24);
          const minutes = Math.floor((ecouleMs / (1000 * 60)) % 60);
          const secondes = Math.floor((ecouleMs / 1000) % 60);
    
          const heuresFormatees = String(heures).padStart(2, '0');
          const minutesFormatees = String(minutes).padStart(2, '0');
          const secondesFormatees = String(secondes).padStart(2, '0');
    
          setTempsEcoule(`${heuresFormatees}:${minutesFormatees}:${secondesFormatees}`);
        
          const totalHeures = heures + minutes / 60 + secondes / 3600;
          setSalaireJournalier(Math.floor(totalHeures * mySheet?.salaireMensuel/208));
        };
    
        // Mettre à jour toutes les secondes
        const interval = mySheet.entree && setInterval(calculerTempsEcoule, 1000);
    
        // Nettoyer l'intervalle quand le composant est démonté
        return () => clearInterval(interval);


      }, [mySheet?.entree,mySheet?.salaireMensuel/208]);

      const handleEndButtonClick = (sheetId) => {
        // Lancer une action pour mettre à jour la fiche dans le reducer
        const { workedHours, workedMinutes} = calculateWorkTimeAndDelay(mySheet.debut, mySheet.entree, mySheet.fin, mySheet.sortie);
        const totalSalaire = workedHours * salaireJournalier;
        
        dispatch(updateSheetInfo(sheetId, workedHours, workedMinutes, totalSalaire));
    };
            
    return (
        <div className="container">
            <h3>Mon Emploi</h3>

            {myHumanRessources && myHumanRessources.map((resource, i) => {
        // Extraire la date de début associée à l'emploi du temps
        const debut = resource.debut; // Supposons que `debut` est la date stockée dans la ressource
        const { formattedStart, formattedEnd } = calculateWeekDates(debut);

        return (


                <div key={i}>
                    <h4>Emploi du Temps pour {resource.owner?.name || 'Employé Inconnu'}</h4>
                    {/* Affichage de l'intervalle de dates */}
          <div className="emploi-semaine">
              <span>
                Emploi de la semaine du : {formattedStart} au {formattedEnd}
              </span>
            </div>
                    {/* Tableau Emploi du temps */}
                    <div className="table-emploi">
                        <table>
                            <thead>
                                <tr>
                                    <th>Jour</th>
                                    {creneaux.map((creneau, index) => (
                                        <th key={index}>{creneau.label}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {jours.map((jour, jourIndex) => (
                                    <tr key={jourIndex}>
                                        <td>{jour}</td>
                                        {creneaux.map((_, creneauIndex) => {
                                            const cellKey = `${resource.owner?._id}-${jourIndex}-${creneauIndex}`;
                                            return (
                                                <td
                                                    key={creneauIndex}
                                                    style={{
                                                        backgroundColor: resource.emploisDuTemps?.[cellKey] ? "red" : "white",
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    {resource.emploisDuTemps?.[cellKey] ? "✓" : ""}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {/* Premier tableau des heures et actions */}
                    <div className="salaire-section">
                       
                        <table>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Début</th>
                                    <th>Fin</th>
                                    <th>Total Heures</th>
                                    <th>Entrée</th>
                                    <th>Sortie</th>
                                    <th>Action</th>
                                    <th>Heures Travaillées</th>
                                    <th>Salaire</th>
                                    <th>Pointage</th>
                                </tr>
                            </thead>
                            <tbody>
                                {mySheets
                                    .filter((el) => el.HumanRessources === resource._id && dateNow.formattedDate === el.date )
                                    .map((sheet) => (
                                        <tr key={sheet._id}>
                                            <td>{sheet.date}</td>
                                            <td>{sheet.debut}</td>
                                            <td>{sheet.fin}</td>
                                            <td>{sheet.totalHeures}</td>
                                            <td>{sheet.entree}</td>
                                            <td>{sheet.sortie}</td>
                                            <td>{sheet.salaireNet}</td>

                                            {sheet.start == true && 
                                            <>
                                            <td> {tempsEcoule}</td>
                                            <td>{salaireJournalier}</td>
                                            </>}
                                            <td>
    {(sheet.start === false && sheet.end === false)
        ? <ValidEntree 
              userId={user._id} 
              sheetId={sheet._id} 
              getNow={getNow} 
              targetPosition={targetPositions} 
          />
        : (sheet.start === true && sheet.end === false)
            ? <ValidSortie 
                  salaireJournalier={salaireJournalier} 
                  userId={user._id} 
                  sheet={sheet} 
                  getNow={getNow} 
              />
            : null
    }
</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>

                    {/* Deuxième tableau des heures travaillées, retard et pénalité */}
                    <div className="table-pointages">
                        <table>
                            <thead>
                                <tr>
                                    <th>Salaire Net</th>
                                    <th>Retard en Heures et Minutes</th>
                                    <th>Pénalité en (DT)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {mySheets
                                
                                    .filter((el) => el.HumanRessources === resource._id && dateNow.formattedDate === el.date && el.debut <= dateNow.formattedTime && el.fin >= dateNow.formattedTime)
                                    .map((sheet) => {
                                        const { workedHours, workedMinutes, delayHours, delayMinutes, penaltyDT } = calculateWorkTimeAndDelay(sheet.debut, sheet.entree, sheet.fin, sheet.sortie);
                                        return (
                                            <tr key={sheet._id}>
                                                <td>{salaireJournalier}</td>
                                                <td>{delayHours}h {delayMinutes}m</td>
                                                <td>{(sheet.penaliteTotale/208).toFixed(3)}</td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>
                    
                </div>
            )})}
        </div>
    );
};

export default MyEmploi;



  