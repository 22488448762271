import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import './CollecteTwin.css' ; // Feuille CSS pour styles
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {AddCollecteTwin} from '../Redux/Actions/RapportTwinAction'


const CollecteTwin = () => {
  const [emplacement, setEmplacement] = useState('');
  const [objet, setObjet] = useState('');
  const [date] = useState(new Date().toISOString().split('T')[0]);
  const [amount, setAmount] = useState(0);
  const [expense, setExpense] = useState(0);
  const [explanation, setExplanation] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleRapport = (e) => {
    e.preventDefault();

    // Validation des champs
    if (!emplacement || !objet ||  !date || amount <= 0 || expense < 0 || !explanation) {
      alert("Veuillez remplir tous les champs correctement.");
      return;
    }
//     const currentDate = new Date();

// const day = currentDate.getDate(); // Day of the month (1-31)
// const month = currentDate.getMonth() + 1; // Month (0-11, so we add 1)
// const year = currentDate.getFullYear(); // Year (e.g., 2024)
// `${year}-${month}-${day}`

    // Données à envoyer
    const rapportData = {
      DateCollect : date.slice(0,10),
      localisation : emplacement,
      objet : objet,
      TotalAmount : parseFloat(amount),
      DailyExpenses: parseFloat(expense),
      feedback :explanation,
      CollecteMachines : objet == "Collecte Machines" ? amount : 0,
      MachineBoxe : objet == "Machine de Boxe" ? amount : 0 ,
      MachineFoot : objet == "Machine de Foot" ? amount : 0,
      MachineBoxeEnfant : objet == "Machine de Boxe Enfant" ? amount : 0,
      BillardAmericain : objet == "Billard Americain" ? amount : 0,
      BabyFoot : objet == "Baby Foot" ? amount : 0,
      BallesGift : objet == "Distributeur de Balles Gift" ? amount : 0,
      Cinema : objet == "Cinema 10D" ? amount : 0,

    };

    

    dispatch(AddCollecteTwin(rapportData,navigate))
    navigate('/ListCollectTwin')
   
  };

  return (

    <div className="list-rapports">   
      <h3>Rapport Collectes & Partenariat</h3>

    <Form className="rapport-form">
      
        <Form.Group as={Col} controlId="formGridState" className="rapport-form-group">
          <Form.Label>Emplacement</Form.Label>
          <Form.Select 
            onChange={(e) => setEmplacement(e.target.value)} 
            className="rapport-select" 
            defaultValue="Choisissez un emplacement...">
            
            
            <option disabled>Choisissez un emplacement...</option>
            <option>Manar City</option>
            <option>Tunisia Mall</option>
            <option>Jardins d'El Menzah</option>
            <option>Jump Zone</option>
            <option>Palmarium</option>
            
         
          </Form.Select>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridState" className="rapport-form-group">
          <Form.Label>Objet</Form.Label>
          <Form.Select 
            onChange={(e) => setObjet(e.target.value)} 
            className="rapport-select" 
            defaultValue="Types de Collecte...">

            <option disabled>Types de Collecte...</option>
            <option>Collecte Machines</option>
            <option>Machine de Boxe</option>
            <option>Machine de Foot</option>
            <option>Machine de Boxe Enfant</option>
            <option>Billard Americain</option>
            <option>Baby Foot</option>
            <option>Distributeur de Balles Gift</option>
            <option>Cinema 10D</option>
            </Form.Select>
        </Form.Group>
      
      <Row>
      <Form.Group as={Col} controlId="formGridDate" className="rapport-form-group">
      <Form.Label>Date du jour</Form.Label>
      <Form.Control 
        type="date" 
        className="rapport-input" 
        value={date} 
        readOnly // Rend le champ non modifiable
      />
    </Form.Group>

        <Form.Group as={Col} controlId="formGridAmount" className="rapport-form-group">
          <Form.Label>Montant</Form.Label>
          <Form.Control 
            onChange={(e) => setAmount(e.target.value)} 
            type="number" 
            className="rapport-input" 
            placeholder="Entrer le total Collect" 
            min="0"
          />
        </Form.Group>
      </Row>

      <Form.Group controlId="formGridExpenses" className="rapport-form-group">
        <Form.Label>Dépenses effectuées</Form.Label>
        <Form.Control 
          onChange={(e) => setExpense(e.target.value)} 
          type="number" 
          className="rapport-input" 
          min="0"
        />
      </Form.Group>

      <Form.Group controlId="explanationTextarea" className="rapport-form-group">
        <Form.Label>Explication des dépenses</Form.Label>
        <Form.Control 
          onChange={(e) => setExplanation(e.target.value)} 
          as="textarea" 
          rows={3} 
          className="rapport-textarea" 
        />
      </Form.Group>

      <Form.Group controlId="formGridCheckbox" className="mb-3">
        <Form.Check 
          type="checkbox" 
          label="Confirmer vos données" 
          required
        />
      </Form.Group>

      <Button onClick={(e) => handleRapport(e)} variant="primary" type="submit" className="rapport-button" >
        Soumettre
      </Button>
    </Form>
    </div>
  );
};

export default CollecteTwin;
