import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getrapporttwin } from "../Redux/Actions/RapportTwinAction";
import CardRapportTwin from "./CardRapportTwin";
import './ListRapportsTwin.css';
import { Table, Button } from 'react-bootstrap';
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";



function ListeRapportsTwin() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getrapporttwin());
  }, [dispatch]);

  const rapports = useSelector((state) => state.RapportTwinReducer.RapportsTwin) 

  const calcul = (data) => {
    const totalAmount = data.reduce((acc, cur) => acc + (cur.TotalAmount || 0), 0);
    const totalDailyExpenses = data.reduce((acc, cur) => acc + (cur.DailyExpenses || 0), 0);
    const resultatnet = totalAmount - totalDailyExpenses;

    const playZoneStandard = data.reduce((acc, cur) => acc + (cur.PlayZoneStandard || 0), 0);
    const playZoneSurveillé = data.reduce((acc, cur) => acc + (cur.PlayZoneSurveillé || 0), 0);
    const accesParent = data.reduce((acc, cur) => acc + (cur.AccesParent || 0), 0);
    const packQR = data.reduce((acc, cur) => acc + (cur.PackQR || 0), 0);
    const rechargeQR = data.reduce((acc, cur) => acc + (cur.RechargeQR || 0), 0);
    const packCard = data.reduce((acc, cur) => acc + (cur.PackCard || 0), 0);
    const rechargeCard = data.reduce((acc, cur) => acc + (cur.RechargeCard || 0), 0);
    const twinClub = data.reduce((acc, cur) => acc + (cur.TwinClub || 0), 0);
    const twinToys = data.reduce((acc, cur) => acc + (cur.TwinToys || 0), 0);
    const challengeGames = data.reduce((acc, cur) => acc + (cur.ChallengeGames || 0), 0);
    const candyBar = data.reduce((acc, cur) => acc + (cur.CandyBar || 0), 0);
    const packAnnif = data.reduce((acc, cur) => acc + (cur.PackAnnif || 0), 0);

    return {
      totalAmount,
      totalDailyExpenses,
      resultatnet,
      playZoneStandard,
      playZoneSurveillé,
      accesParent,
      packQR,
      rechargeQR,
      packCard,
      rechargeCard,
      twinClub,
      twinToys,
      challengeGames,
      candyBar,
      packAnnif,
    };
  };

  const [somme, setSomme] = useState({
    totalAmount: 0,
    totalDailyExpenses: 0,
    resultatnet: 0,
    playZoneStandard: 0,
    playZoneSurveillé: 0,
    accesParent: 0,
    packQR: 0,
    rechargeQR: 0,
    packCard: 0,
    rechargeCard: 0,
    twinClub: 0,
    twinToys: 0,
    challengeGames: 0,
    candyBar: 0,
    packAnnif: 0,
  });

  const [localisation, setLocalisation] = useState("All");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [recherche,setRecherche] = useState(false)

  const filterData = () => {
    setRecherche(true)
    if (!Array.isArray(rapports) || rapports.length === 0) {
      setFilteredData([]);
      return;
    }

    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;

    const filtered = rapports.filter((entry) => {
      const entryDate = new Date(entry.DateCollect);
      const isLocationMatch = localisation === "All" || entry.localisation === localisation;
      const isDateInRange = (!start || entryDate >= start) && (!end || entryDate <= end);
      return isLocationMatch && isDateInRange;
    });
console.log(filtered)
    setFilteredData(filtered);
    setSomme(calcul(filtered));
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
  
    // En-tête
    doc.text("Rapport de Résultats", 20, 10);
  
    // Ajout des données du résumé dans le tableau
    const data = [
      ["Localisation", localisation],
      ["PlayZone Standard", somme.playZoneStandard],
      ["PlayZone Surveillé", somme.playZoneSurveillé],
      ["Acces Parent", somme.accesParent],
      ["Pack QR", somme.packQR],
      ["Recharge QR", somme.rechargeQR],
      ["Pack Card", somme.packCard],
      ["Recharge Card", somme.rechargeCard],
      ["Twin Club", somme.twinClub],
      ["Twin Toys", somme.twinToys],
      ["Challenge Games", somme.challengeGames],
      ["Candy Bar", somme.candyBar],
      ["Pack Anniversaire", somme.packAnnif],
      ["Amount", somme.totalAmount],
      ["Expenses", somme.totalDailyExpenses],
      ["Net", somme.resultatnet],
    ];
  
    // Génération du tableau PDF
    doc.autoTable({
      head: [["Catégorie", "Valeur"]],
      body: data,
    });
  
    doc.save("rapport_resultats.pdf");
  };


const downloadExcel = () => {
  const wsData = [
    ["Catégorie", "Valeur"],
    ["Localisation", localisation],
    ["PlayZone Standard", somme.playZoneStandard],
    ["PlayZone Surveillé", somme.playZoneSurveillé],
    ["Acces Parent", somme.accesParent],
    ["Pack QR", somme.packQR],
    ["Recharge QR", somme.rechargeQR],
    ["Pack Card", somme.packCard],
    ["Recharge Card", somme.rechargeCard],
    ["Twin Club", somme.twinClub],
    ["Twin Toys", somme.twinToys],
    ["Challenge Games", somme.challengeGames],
    ["Candy Bar", somme.candyBar],
    ["Pack Anniversaire", somme.packAnnif],
    ["Amount", somme.totalAmount],
    ["Expenses", somme.totalDailyExpenses],
    ["Net", somme.resultatnet],
  ];

  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet(wsData);
  XLSX.utils.book_append_sheet(wb, ws, "Rapport de Résultats");

  XLSX.writeFile(wb, "rapport_resultats.xlsx");
};


  return (
    <div className="liste-rapports-twin">
      <h2>Recherche de Rapport Twin Park</h2>
      <div className="filter-form">
        <label>
          Localisation:
          <select value={localisation} onChange={(e) => setLocalisation(e.target.value)}>
            <option value="All">All</option>
            <option>Twin Park- Manar City</option>
            <option>Twin Park - Tunisia Mall</option>
            <option>Twin park - Jardins d'eL Menzah</option>
            <option>Jump Zone Mall of Djerba</option>
          </select>
        </label>

        <label>
          Start Date:
          <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        </label>

        <label>
          End Date:
          <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        </label>

        <button onClick={filterData}>Confirm</button>
      </div>
    {
      recherche &&
      <div className="filtered-data">
        <h3>Résultats trouvés</h3>
        <div className="results-summary">
          <h3>Localisation : {localisation}</h3>
          <h3>PlayZone Standard: {somme.playZoneStandard}</h3>
          <h3>PlayZone Surveillé: {somme.playZoneSurveillé}</h3>
          <h3>Acces Parent: {somme.accesParent}</h3>
          <h3>Pack QR: {somme.packQR}</h3>
          <h3>Recharge QR: {somme.rechargeQR}</h3>
          <h3>Pack Card: {somme.packCard}</h3>
          <h3>Recharge Card: {somme.rechargeCard}</h3>
          <h3>Twin Club: {somme.twinClub}</h3>
          <h3>Twin Toys: {somme.twinToys}</h3>
          <h3>Challenge Games: {somme.challengeGames}</h3>
          <h3>Candy Bar: {somme.candyBar}</h3>
          <h3>Pack Anniversaire: {somme.packAnnif}</h3>
          <h3>Amount: {somme.totalAmount.toFixed(3)}</h3>
          <h3>Expenses: {somme.totalDailyExpenses.toFixed(3)}</h3>
          <h3>Net: {somme.resultatnet.toFixed(3)}</h3>
        </div>

        <Button variant="primary" onClick={downloadPDF} style={{ marginRight: "10px" }}>
          Télécharger en PDF
        </Button>
        <Button variant="success" onClick={downloadExcel}>
          Télécharger en Excel
        </Button>

        {filteredData.length > 0 ? (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Rapport</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((el, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td><CardRapportTwin el={el} /></td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p>Aucun résultat trouvé</p>
        )}
      </div>
    }
    </div>

    // <div>
      
    //   <div>
    //     {
    //       rapports.map((el,i,t)=> <CardRapportTwin el={el}/>)
    //     }
    //   </div>
    // </div>
  );
}

export default ListeRapportsTwin;
