import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { current, updateProfil } from '../Redux/Actions/AuthActions';

const UpdateProfil = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(current());
  }, [dispatch]);

  const user = useSelector((state) => state.AuthReducer.user);

  const [name, setName] = useState(user.name || '');
  const [email, setEmail] = useState(user.email || '');
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setName(user.name || '');
    setEmail(user.email || '');
  }, [user]);

  const navigate = useNavigate();

  const handleUpdate = (e) => {
    e.preventDefault();

    if (password && password !== confirmPassword) {
      setErrorMessage('Les mots de passe ne correspondent pas.');
      return;
    }

    if (!oldPassword) {
      setErrorMessage("L'ancien mot de passe est requis.");
      return;
    }

    const updatedData = { name, email, oldPassword };
    if (password) {
      updatedData.password = password;
    }

    dispatch(updateProfil(user._id, updatedData, navigate));
  };

  return (
    <Form>
      <Form.Group className="mb-3">
        <Form.Label>Name</Form.Label>
        <Form.Control
          value={name}
          onChange={(e) => setName(e.target.value)}
          type="text"
          placeholder="Enter Name"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Email</Form.Label>
        <Form.Control
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          placeholder="Enter email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Ancien mot de passe</Form.Label>
        <Form.Control
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          type="password"
          placeholder="Enter old password"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Nouveau mot de passe</Form.Label>
        <Form.Control
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          placeholder="Enter new password"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Confirmer le mot de passe</Form.Label>
        <Form.Control
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          type="password"
          placeholder="Confirm password"
        />
      </Form.Group>

      {errorMessage && (
        <div style={{ color: 'red', marginBottom: '10px' }}>{errorMessage}</div>
      )}

      <Button onClick={(e) => handleUpdate(e)} variant="primary" type="submit">
        Submit
      </Button>
    </Form>
  );
};

export default UpdateProfil;
