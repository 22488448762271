import { GETHR, GETMYHR, ADD_HR, UPDATE_HR_SUCCESS, DELETE_HR } from "../ActionTypes/HumainRessourcesTypes";

const initialState = {
  humanRessources: [],  // Liste des ressources humaines
  myHumanRessources: [], // Liste des ressources humaines de l'utilisateur
};

const HumanRessourcesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETHR:
      return { ...state, humanRessources: action.payload };

    case GETMYHR:
      return { ...state, myHumanRessources: action.payload };

      case UPDATE_HR_SUCCESS:
      return {
        ...state,
        humanRessources: state.humanRessources.map((hr) =>
          hr._id === action.payload._id ? action.payload : hr
        ),
      };
    case DELETE_HR:
      return {
        ...state,
        humanRessources: state.humanRessources.filter((hr) => hr._id !== action.payload),
      };
    default:
      return state;
  }
};

export default HumanRessourcesReducer;
