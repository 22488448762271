import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import './Button.css'
import './Rapports.css'; 
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {AddRapportsTwin} from '../Redux/Actions/RapportTwinAction';
import { current } from "../Redux/Actions/AuthActions";

const RapportsTwin = () => {
  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(current())
  },[])

  const user = useSelector((state) => state.AuthReducer.user);
  
  const [emplacement, setEmplacement] = useState('');
  const [date] = useState(new Date().toISOString().split('T')[0]);
  const [playZoneStandard, setPlayzoneStandard] = useState(0);
  const [playZoneSurveillé, setPlayzonesurveillé] = useState(0);
  const [accesParent, setAccesParent] = useState(0);
  const [packQR, setPackQR] = useState(0);
  const [rechargeQR, setRechargeQR] = useState(0);
  const [packCard, setPackCard] = useState(0);
  const [rechargeCard, setRechargeCard] = useState(0);
  const [twinClub, setTwinClub] = useState(0);
  const [twinToys, setTwinToys] = useState(0);
  const [challengeGames, setChallengeGames] = useState(0);
  const [candyBar, setCandybar] = useState(0);
  const [packAnnif, setPackannif] = useState(0);
  const [amount, setAmount] = useState(0);
  const [expense, setExpense] = useState(0);
  const [explanation, setExplanation] = useState('');


  const navigate = useNavigate();

  const calculateTotalAmount = () => {
    return (
      parseFloat(playZoneStandard) +
      parseFloat(playZoneSurveillé) +
      parseFloat(accesParent) +
      parseFloat(packQR) +
      parseFloat(rechargeQR) +
      parseFloat(packCard) +
      parseFloat(rechargeCard) +
      parseFloat(twinClub) +
      parseFloat(twinToys) +
      parseFloat(challengeGames) +
      parseFloat(candyBar) +
      parseFloat(packAnnif)
    ).toFixed(3);
  };

  const handleNumberInput = (e, setState) => {
    const value = e.target.value;
    if (/^\d*\.?\d{0,3}$/.test(value)) {
      setState(value);
    }
  };

  const existingReports = useSelector((state) => state.RapportTwinReducer.rapportsTwin || []);


  const handleRapportTwin = (e) => {
      e.preventDefault();
  
      if (!emplacement || !date || !explanation) {
          alert("Veuillez remplir tous les champs correctement.");
          return;
      }
  
      // Vérification de doublon
      const duplicateReport = existingReports.find(
          (report) =>
              report.localisation === emplacement &&
              report.DateCollect === date
      );
  
      if (duplicateReport) {
          alert("Un rapport pour cette localisation et cette date existe déjà.");
          return;
      }
  
      // Confirmation avant soumission
      const confirmSubmit = window.confirm("Voulez-vous confirmer les informations saisies ?");
      if (!confirmSubmit) {
          return;
      }
  
      const rapportData = {
          DateCollect: date.slice(0, 10),
          localisation: emplacement,
          PlayZoneStandard: parseFloat(playZoneStandard),
          PlayZoneSurveillé: parseFloat(playZoneSurveillé),
          AccesParent: parseFloat(accesParent),
          PackQR: parseFloat(packQR),
          RechargeQR: parseFloat(rechargeQR),
          PackCard: parseFloat(packCard),
          RechargeCard: parseFloat(rechargeCard),
          TwinClub: parseFloat(twinClub),
          TwinToys: parseFloat(twinToys),
          ChallengeGames: parseFloat(challengeGames),
          CandyBar: parseFloat(candyBar),
          PackAnnif: parseFloat(packAnnif),
          TotalAmount: parseFloat(calculateTotalAmount()),
          DailyExpenses: parseFloat(expense),
          feedback: explanation,
          owner: user._id,
      };
  
      dispatch(AddRapportsTwin(rapportData, navigate));
  };
  

  return (
    <div className="list-rapports">   
      <h3>Rapport Twin Park</h3>

    <Form className="rapport-form" onSubmit={handleRapportTwin}>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridState" className="rapport-form-group">
          <Form.Label>Emplacement</Form.Label>
          <Form.Select 
            onChange={(e) => setEmplacement(e.target.value)} 
            className="rapport-select" 
            defaultValue="Choisissez un emplacement...">
            <option disabled>Choisissez un emplacement...</option>
            <option>Twin Park- Manar City</option>
            <option>Twin Park - Tunisia Mall</option>
            <option>Twin park - Jardins d'eL Menzah</option>
            <option>Jump Zone Mall of Djerba</option>
          </Form.Select>
        </Form.Group>
          
        <Form.Group as={Col} controlId="formGridDate" className="rapport-form-group">
      <Form.Label>Date du jour</Form.Label>
      <Form.Control 
        type="date" 
        className="rapport-input" 
        value={date} 
        readOnly // Rend le champ non modifiable
      />
    </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col} controlId="formPlayZoneStandard" className="rapport-form-group">
          <Form.Label>PlayZone Standard</Form.Label>
          <Form.Control 
            type="text" 
            value={playZoneStandard}
            onChange={(e) =>  setPlayzoneStandard(e.target.value)}
            className="rapport-input" 
            placeholder="0" 
          />
        </Form.Group>

        <Form.Group as={Col} controlId="formPlayZoneSurveillé" className="rapport-form-group">
          <Form.Label>PlayZone Surveillé</Form.Label>
          <Form.Control 
            type="text" 
            value={playZoneSurveillé}
            onChange={(e) => handleNumberInput(e, setPlayzonesurveillé)}
            className="rapport-input" 
            placeholder="0" 
          />
        </Form.Group>

        <Form.Group as={Col} controlId="formAccesParent" className="rapport-form-group">
          <Form.Label>Accès Parents</Form.Label>
          <Form.Control 
            type="text" 
            value={accesParent}
            onChange={(e) => handleNumberInput(e, setAccesParent)}
            className="rapport-input" 
            placeholder="0" 
          />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col} controlId="formPackQR" className="rapport-form-group">
          <Form.Label>Pack QR</Form.Label>
          <Form.Control 
            type="text" 
            value={packQR}
            onChange={(e) => handleNumberInput(e, setPackQR)}
            className="rapport-input" 
            placeholder="0" 
          />
        </Form.Group>

        <Form.Group as={Col} controlId="formRechargeQR" className="rapport-form-group">
          <Form.Label>Recharge QR</Form.Label>
          <Form.Control 
            type="text" 
            value={rechargeQR}
            onChange={(e) => handleNumberInput(e, setRechargeQR)}
            className="rapport-input" 
            placeholder="0" 
          />
        </Form.Group>

        <Form.Group as={Col} controlId="formPackCard" className="rapport-form-group">
          <Form.Label>Pack Card</Form.Label>
          <Form.Control 
            type="text" 
            value={packCard}
            onChange={(e) => handleNumberInput(e, setPackCard)}
            className="rapport-input" 
            placeholder="0" 
          />
        </Form.Group>
      </Row>

      <Row>
        
        <Form.Group as={Col} controlId="formRechargeCard" className="rapport-form-group">
          <Form.Label>Recharge Card</Form.Label>
          <Form.Control 
            type="text" 
            value={rechargeCard}
            onChange={(e) => handleNumberInput(e, setRechargeCard)}
            className="rapport-input" 
            placeholder="0" 
          />
        </Form.Group>
      
        <Form.Group as={Col} controlId="formTwinToys" className="rapport-form-group">
          <Form.Label>Twin Toys</Form.Label>
          <Form.Control 
            type="text" 
            value={twinToys}
            onChange={(e) => handleNumberInput(e, setTwinToys)}
            className="rapport-input" 
            placeholder="0" 
          />
        </Form.Group>

        <Form.Group as={Col} controlId="formTwinClub" className="rapport-form-group">
          <Form.Label>Twin Club</Form.Label>
          <Form.Control 
            type="text" 
            value={twinClub}
            onChange={(e) => handleNumberInput(e, setTwinClub)}
            className="rapport-input" 
            placeholder="0" 
          />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col} controlId="formChallengeGames" className="rapport-form-group">
          <Form.Label>Challenge Games</Form.Label>
          <Form.Control 
            type="text" 
            value={challengeGames}
            onChange={(e) => handleNumberInput(e, setChallengeGames)}
            className="rapport-input" 
            placeholder="0" 
          />
        </Form.Group>

        <Form.Group as={Col} controlId="formCandyBar" className="rapport-form-group">
          <Form.Label>Candy Bar</Form.Label>
          <Form.Control 
            type="text" 
            value={candyBar}
            onChange={(e) => handleNumberInput(e, setCandybar)}
            className="rapport-input" 
            placeholder="0" 
          />
        </Form.Group>

        <Form.Group as={Col} controlId="formPackAnnif" className="rapport-form-group">
          <Form.Label>Pack Anniversaire</Form.Label>
          <Form.Control 
            type="text" 
            value={packAnnif}
            onChange={(e) => handleNumberInput(e, setPackannif)}
            className="rapport-input" 
            placeholder="0" 
          />
        </Form.Group>
      </Row>

     

      <Row>
        <Form.Group as={Col} controlId="formGridAmount" className="rapport-form-group">
          <Form.Label>Montant Total</Form.Label>
          <Form.Control 
            value={calculateTotalAmount()}
            readOnly
            type="text" 
            className="rapport-input" 
          />
        </Form.Group>
      
        <Form.Group as={Col} controlId="formGridExpense" className="rapport-form-group">
          <Form.Label>Depenses</Form.Label>
          <Form.Control 
            type="text" 
            onChange={(e) => handleNumberInput(e, setExpense)}
            className="rapport-input" 
            placeholder="0" 
          />
        </Form.Group>
        </Row>

        <Form.Group as={Col} controlId="formExplanation" className="rapport-form-group">
          <Form.Label>Explication</Form.Label>
          <Form.Control 
            as="textarea" 
            rows={2} 
            onChange={(e) => setExplanation(e.target.value)} 
            placeholder="Entrez une explication"
          />
        </Form.Group>
      

        <Button 
  onClick={(e) => handleRapportTwin(e)} variant="primary" type="submit" className="rapport-button"
>
  Soumettre
</Button>
    </Form>
    </div>
  );
};

export default RapportsTwin;
