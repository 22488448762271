import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { updateSheet } from '../Redux/Actions/SheetActions';
import { useDispatch } from 'react-redux';

const haversineDistance = (coords1, coords2) => {
    const toRad = (value) => (value * Math.PI) / 180;
    const R = 6371e3; // Rayon de la Terre en mètres
    const lat1 = toRad(coords1.lat);
    const lat2 = toRad(coords2.lat);
    const deltaLat = toRad(coords2.lat - coords1.lat);
    const deltaLon = toRad(coords2.lon - coords1.lon);

    const a = Math.sin(deltaLat / 2) ** 2 +
        Math.cos(lat1) * Math.cos(lat2) * Math.sin(deltaLon / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // Distance en mètres
};

function ValidEntree({ userId, sheetId, getNow, targetPosition }) {
    const [show, setShow] = useState(false);
    const [isWithinRadius, setIsWithinRadius] = useState(false);
    const [loadingLocation, setLoadingLocation] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!navigator.geolocation) {
            console.error("La géolocalisation n'est pas supportée par ce navigateur.");
            setLoadingLocation(false);
            return;
        }

        navigator.geolocation.getCurrentPosition(
            (position) => {
                const userPosition = {
                    lat: position.coords.latitude,
                    lon: position.coords.longitude,
                };
                const distance = haversineDistance(userPosition, targetPosition);
                setIsWithinRadius(distance <= 100); // Vérifiez si dans un rayon de 100 mètres
                setLoadingLocation(false);
            },
            (error) => {
                console.error("Erreur lors de l'obtention de la position : ", error);
                setLoadingLocation(false);
            }
        );
    }, [targetPosition]);

    return (
        <>
            <Button
                variant="primary"
                onClick={handleShow}
                disabled={isWithinRadius || loadingLocation}
            >
                Start
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Start</Modal.Title>
                </Modal.Header>
                <Modal.Body>Validate ? </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        onClick={() => {
                            dispatch(updateSheet(userId, sheetId, { start: true, entree: getNow().formattedTime }));
                            handleClose();
                        }}
                    >
                        Validate
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ValidEntree;
