import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getrapporttwin } from "../Redux/Actions/RapportTwinAction";
import CardCollectTwin from "./CardCollectTwin";
import { Table, Button } from 'react-bootstrap';
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";

function ListCollectTwin() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getrapporttwin());
  }, [dispatch]);

  const rapports = useSelector((state) => state.RapportTwinReducer.RapportsTwin);

  const [somme, setSomme] = useState({
    Somme: 0,
    totalDailyExpenses: 0,
    resultatnet: 0,
    CollecteMachinesS: 0,
    MachineBoxeS: 0,
    MachineFootS: 0,
    MachineBoxeEnfantS: 0,
    BillardAmericainS: 0,
    BabyFootS: 0,
    BallesGiftS: 0,
    CinemaS: 0,
  });

  const [localisation, setLocalisation] = useState("All");
  const [objet, setObjet] = useState('All');
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [recherche, setRecherche] = useState(false);

  const calcul = (t) => {
    const totals = {
      Somme: 0,
      totalDailyExpenses: 0,
      CollecteMachinesS: 0,
      MachineBoxeS: 0,
      MachineFootS: 0,
      MachineBoxeEnfantS: 0,
      BillardAmericainS: 0,
      BabyFootS: 0,
      BallesGiftS: 0,
      CinemaS: 0,
    };
  
    t.forEach((entry) => {
      if (objet === "All" || objet === "Collecte Machines") {
        totals.CollecteMachinesS += Number(entry.CollecteMachines || 0);
      }
      if (objet === "All" || objet === "Machine de Boxe") {
        totals.MachineBoxeS += Number(entry.MachineBoxe || 0);
      }
      if (objet === "All" || objet === "Machine de Foot") {
        totals.MachineFootS += Number(entry.MachineFoot || 0);
      }
      if (objet === "All" || objet === "Machine de Boxe Enfant") {
        totals.MachineBoxeEnfantS += Number(entry.MachineBoxeEnfant || 0);
      }
      if (objet === "All" || objet === "Billard Americain") {
        totals.BillardAmericainS += Number(entry.BillardAmericain || 0);
      }
      if (objet === "All" || objet === "Baby Foot") {
        totals.BabyFootS += Number(entry.BabyFoot || 0);
      }
      if (objet === "All" || objet === "Distributeur de Balles Gift") {
        totals.BallesGiftS += Number(entry.BallesGift || 0);
      }
      if (objet === "All" || objet === "Cinema 10D") {
        totals.CinemaS += Number(entry.Cinema || 0);
      }
      if (objet === "All") {
        totals.Somme += Number(entry.TotalAmount || 0);
        totals.totalDailyExpenses += Number(entry.DailyExpenses || 0);
      }
    });
  
    totals.resultatnet = totals.Somme - totals.totalDailyExpenses;
  
    // Si un champ n'est pas sélectionné, laissez-le explicitement à 0
    if (objet !== "All" && objet !== "Collecte Machines") totals.CollecteMachinesS = 0;
    if (objet !== "All" && objet !== "Machine de Boxe") totals.MachineBoxeS = 0;
    if (objet !== "All" && objet !== "Machine de Foot") totals.MachineFootS = 0;
    if (objet !== "All" && objet !== "Machine de Boxe Enfant") totals.MachineBoxeEnfantS = 0;
    if (objet !== "All" && objet !== "Billard Americain") totals.BillardAmericainS = 0;
    if (objet !== "All" && objet !== "Baby Foot") totals.BabyFootS = 0;
    if (objet !== "All" && objet !== "Distributeur de Balles Gift") totals.BallesGiftS = 0;
    if (objet !== "All" && objet !== "Cinema 10D") totals.CinemaS = 0;
  
   
  
    return totals;
  };
  

  const filterData = () => {
    setRecherche(true);
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;

    const filtered = rapports.filter((entry) => {
      const entryDate = new Date(entry.DateCollect);
      const isLocationMatch = localisation === "All" || entry.localisation === localisation;
      const isObjetMatch = objet === "All" || entry.objet === objet;
      const isDateInRange = (!start || entryDate >= start) && (!end || entryDate <= end);

      return isLocationMatch && isObjetMatch && isDateInRange;
    });

    setFilteredData(filtered);
    setSomme(calcul(filtered));
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Rapport Résultats", 10, 10);
    doc.autoTable({
      startY: 20,
      head: [['#', 'Localisation', 'Objet', 'TotalAmount', 'Expenses', 'Net']],
      body: filteredData.map((el, i) => [i + 1, el.localisation, el.objet, el.TotalAmount, el.DailyExpenses, el.TotalAmount - el.DailyExpenses]),
    });
    doc.save('rapport_resultats.pdf');
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      filteredData.map((el, i) => ({
        "#": i + 1,
        Localisation: el.localisation,
        TotalAmount: el.TotalAmount,
        Expenses: el.DailyExpenses,
        Net: el.TotalAmount - el.DailyExpenses,
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Rapports");
    XLSX.writeFile(workbook, 'rapport_resultats.xlsx');
  };

  return (
    <div className="liste-rapports-twin">
      <h2>Recherche des collectes et des Partenariats</h2>
      <div className="filter-form">
        {/* Filtres */}
        <label>
          Localisation:
          <select value={localisation} onChange={(e) => setLocalisation(e.target.value)}>
            <option value="All">All</option>
            <option>Manar City</option>
            <option>Tunisia Mall</option>
            <option>Jardins d'El Menzah</option>
            <option>Jump Zone</option>
            <option>Palmarium</option>
          </select>
        </label>
        <label>
          Nature de Collecte:
          <select value={objet} onChange={(e) => setObjet(e.target.value)}>
            <option value="All">All</option>
            <option>Collecte Machines</option>
            <option>Machine de Boxe</option>
            <option>Machine de Foot</option>
            <option>Machine de Boxe Enfant</option>
            <option>Billard Americain</option>
            <option>Baby Foot</option>
            <option>Distributeur de Balles Gift</option>
            <option>Cinema 10D</option>
          </select>
        </label>
        <label>
          Start Date:
          <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        </label>
        <label>
          End Date:
          <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        </label>
        <button onClick={filterData}>Confirm</button>
      </div>

      {/* Résultats */}
      {recherche && (
        <div className="filtered-data">
          <h3>Résultats trouvés</h3>
          <div className="results-summary">
            <h3>Localisation : {localisation}</h3>
            <h3>Objet : {objet}</h3>
            <h3>Collecte Machines : {somme.CollecteMachinesS}</h3>
            <h3>Machine de Boxe : {somme.MachineBoxeS}</h3>
            <h3>Machine de Foot : {somme.MachineFootS}</h3>
            <h3>Machine de Boxe Enfant : {somme.MachineBoxeEnfantS}</h3>
            <h3>Billard Americain : {somme.BillardAmericainS}</h3>
            <h3>Baby Foot : {somme.BabyFootS}</h3>
            <h3>Distributeur de Balles Gift : {somme.BallesGiftS}</h3>
            <h3>Cinema 10D : {somme.CinemaS}</h3>
            <h3>TotalAmount: {somme.Somme.toFixed(3)}</h3>
            <h3>Expenses: {somme.totalDailyExpenses.toFixed(3)}</h3>
            <h3>Net: {somme.resultatnet.toFixed(3)}</h3>
          </div>

          <Button variant="primary" onClick={downloadPDF} style={{ marginRight: "10px" }}>
            Télécharger en PDF
          </Button>
          <Button variant="success" onClick={downloadExcel}>
            Télécharger en Excel
          </Button>

          {filteredData?.length > 0 ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Rapport</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((el, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td><CardCollectTwin el={el} /></td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>Aucun résultat trouvé</p>
          )}
        </div>
      )}
    </div>
  );
}

export default ListCollectTwin;
