import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import {  useNavigate, useParams } from 'react-router-dom';
import { getOneUser, updateUser } from '../Redux/Actions/AuthActions';

const UpdateUser = () => {

    const{id}=useParams()

    const dispatch =useDispatch()

    useEffect(()=>{

      dispatch(getOneUser(id))

    },[])

    const upUser = useSelector(state=> state.AuthReducer.upUser)

      const [name, setName] = useState('');
      const [email, setEmail] = useState('');
      const [matricule, setMatricule] = useState('');
      const [localisation, setLocalisation] = useState('');
      const [salaire, setSalaire] = useState('');
      const [phone, setPhone] = useState('');
      const [adress, setAdress] = useState('');
      const [cin, setCin] = useState('')
      const [dateembauche, setDateembauche] = useState('')
      const [fonction,setFonction] = useState('')
      const [cnss, setCnss] = useState ('')
      const [role, setRole] = useState("");

useEffect(() => {
  if (upUser) {
    setName(upUser.name || '');
    setEmail(upUser.email || '');
    setMatricule(upUser.matricule || '');
    setLocalisation(upUser.localisation || '');
    setSalaire(upUser.salaire || '');
    setPhone(upUser.phone || '');
    setAdress(upUser.adress || '')
    setCin(upUser.cin || '')
    setDateembauche(upUser.dateembauche || '')
    setFonction(upUser.fonction || '')
    setCnss(upUser.cnss || '');
    setRole(upUser.role || "");
  }
}, [upUser]);


    const navigate = useNavigate()
    const handleUpdate=(a)=>{
      a.preventDefault()
      dispatch(updateUser(id, { name, email, matricule, localisation, salaire, phone, adress,cin, dateembauche, fonction,cnss, role },navigate));

    }

  return (
    <Form>

<div className="row">
            <div className="col-md-6">
              <Form.Group className="mb-3">
                <Form.Label>Nom</Form.Label>
                <Form.Control
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  placeholder="Votre nom"
                  className="input-field"
                  value={name}
                />
              </Form.Group>
            </div>
  
            <div className="col-md-6">
              <Form.Group className="mb-3">
                <Form.Label>Matricule</Form.Label>
                <Form.Control
                  onChange={(e) => setMatricule(e.target.value)}
                  type="text"
                  placeholder="Matricule"
                  className="input-field"
                  value={matricule}
                />
              </Form.Group>
            </div>
          </div>
  
          <div className="row">
            <div className="col-md-6">
              <Form.Group className="mb-3">
                <Form.Label>Adresse Email</Form.Label>
                <Form.Control
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder="Votre email"
                  className="input-field"
                  value={email}
                />
              </Form.Group>
            </div>
           <div> 
            <Form.Group className="mb-3">
              <Form.Label>Rôle</Form.Label>
              <Form.Select
                onChange={(e) => setRole(e.target.value)}
                value={role}
              >
                <option value="">Sélectionner un rôle</option>
                <option value="admin">Admin</option>
                <option value="userspintoss">User Spintoss</option>
                <option value="usertwinpark">User Twinpark</option>
                <option value="userwellness">User Wellness</option>
                <option value="useradmin">User Admin</option>
              </Form.Select>
            </Form.Group>
            </div>

            <div className="col-md-6">
              <Form.Group className="mb-3">
                <Form.Label>Fonction : </Form.Label>
                <Form.Control
                  onChange={(e) => setFonction(e.target.value)}
                  type="Fonction"
                  placeholder="Fonction occupé"
                  className="input-field"
                  value={fonction}
                />
              </Form.Group>
            </div>

            <div className="col-md-6">
              <Form.Group className="mb-3">
                <Form.Label>N° Affiliation CNSS : </Form.Label>
                <Form.Control
                  onChange={(e) => setCnss(e.target.value)}
                  type="CNSS"
                  placeholder="Numéro d'affiliation"
                  className="input-field"
                  value={cnss}
                />
              </Form.Group>
            </div>
          </div>
  
          <div className="row">
            <div className="col-md-6">
              <Form.Group className="mb-4">
                <Form.Label>Localisation</Form.Label>
                <Form.Control
                  onChange={(e) => setLocalisation(e.target.value)}
                  type="text"
                  placeholder="Votre localisation"
                  className="input-field"
                  value={localisation}
                />
              </Form.Group>
            </div>
  
            <div className="col-md-6">
              <Form.Group className="mb-4">
                <Form.Label>Salaire</Form.Label>
                <Form.Control
                  onChange={(e) => setSalaire(e.target.value)}
                  type="text"
                  placeholder="Saisir le salaire"
                  className="input-field"
                  value={salaire}
                />
              </Form.Group>
            </div>
          </div>
  
          <div className="row">
            <div className="col-md-6">
              <Form.Group className="mb-4">
                <Form.Label>Adresse</Form.Label>
                <Form.Control
                  onChange={(e) => setAdress(e.target.value)}
                  type="text"
                  placeholder="Saisir adresse"
                  className="input-field"
                  value={adress}
                />
              </Form.Group>
            </div>
  
            <div className="col-md-6">
              <Form.Group className="mb-4">
                <Form.Label>Numéro de téléphone</Form.Label>
                <Form.Control
                  onChange={(e) => setPhone(e.target.value)}
                  type="text"
                  placeholder="Saisir numéro"
                  className="input-field"
                  value={phone}
                />
              </Form.Group>
            </div>
          </div>




          <div className="row">
            <div className="col-md-6">
              <Form.Group className="mb-4">
                <Form.Label>CIN</Form.Label>
                <Form.Control
                  onChange={(e) => setCin(e.target.value)}
                  type="text"
                  placeholder="Saisir CIN"
                  className="input-field"
                  value={cin}
                />
              </Form.Group>
            </div>
  
            <div className="col-md-6">
              <Form.Group className="mb-4">
                <Form.Label>Date d'embauche</Form.Label>
                <Form.Control
                  onChange={(e) => setDateembauche(e.target.value)}
                  type="text"
                  placeholder="Saisir date"
                  className="input-field"
                  value={dateembauche}
                />
              </Form.Group>
            </div>
          </div>
      
        <Button onClick={(e)=>handleUpdate(e)} variant="primary" type="submit">
          Submit
        </Button>
      </Form>
   
  )

}
export default UpdateUser
