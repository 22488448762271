import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button } from 'react-bootstrap';
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import CardChargeExp from "./CardChargeExp";
import { getchargexp } from "../Redux/Actions/ChargeExpActions";


function ListChargeExp() {
const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getchargexp());
  }, [dispatch]);

const rapports = useSelector((state) => state.ChargeExpReducer.ChargeExpl);
console.log("Rapports dans le composant :", rapports);



  const [somme, setSomme] = useState({
    Somme: 0,
    totalDailyExpenses: 0,
    resultatnet: 0,
    LoyersL : 0,
    BanquesL : 0,
    FiscL :  0,
    SalaireL :  0,
    StegL :  0,
    SonedeL :  0,
    CnssL : 0, 
    AssuranceL :  0, 
    EntretienL :  0,
    OperateurTelL :  0,
    OperateurFibreL :  0,
    FournituresBureauL :  0, 
    AchatsToysL : 0, 
    AchatsCandyBarL : 0,
  });

  const [localisation, setLocalisation] = useState("All");
  const [objet, setObjet] = useState('All');
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [recherche, setRecherche] = useState(false);

  const calcul = (t) => {
    const totals = {
      Somme: 0,
      totalDailyExpenses: 0,
      LoyersL : 0,
      BanquesL : 0,
      FiscL :  0,
      SalaireL :  0,
      StegL :  0,
      SonedeL :  0,
      CnssL : 0, 
      AssuranceL :  0, 
      EntretienL :  0,
      OperateurTelL :  0,
      OperateurFibreL :  0,
      FournituresBureauL :  0, 
      AchatsToysL : 0, 
      AchatsCandyBarL : 0,
    };
  
    t.forEach((entry) => {
      if (objet === "All" || objet === "Loyers") {
        totals.LoyersL += Number(entry.Loyers || 0);
      }
      if (objet === "All" || objet === "Banques") {
        totals.BanquesL += Number(entry.Banques || 0);
      }
      if (objet === "All" || objet === "Recettes Fiscales") {
        totals.FiscL += Number(entry.Fisc || 0);
      }
      if (objet === "All" || objet === "Les Salaires") {
        totals.SalaireL += Number(entry.Salaire || 0);
      }
      if (objet === "All" || objet === "STEG") {
        totals.StegL += Number(entry.Steg || 0);
      }
      if (objet === "All" || objet === "SONEDE") {
        totals.SonedeL += Number(entry.Sonede || 0);
      }
      if (objet === "All" || objet === "Assurance") {
        totals.AssuranceL += Number(entry.Assurance || 0);
      }
      if (objet === "All" || objet === "CNSS") {
        totals.CnssL += Number(entry.Cnss || 0);
      }

      if (objet === "All" || objet === "Entretien Voitures") {
        totals.EntretienL += Number(entry.Entretien || 0);
      }
      if (objet === "All" || objet === "Opérarteur Téléphoniques") {
        totals.OperateurTelL += Number(entry.OperateurTel || 0);
      }
      if (objet === "All" || objet === "Opérateur Internet") {
        totals.OperateurFibreL += Number(entry.OperateurFibre || 0);
    }
    if (objet === "All" || objet === "Fournitures de Bureau") {
      totals.FournituresBureauL += Number(entry.FournituresBureau || 0);
    }
    if (objet === "All" || objet === "Achats Jouets") {
      totals.AchatsToysL += Number(entry.AchatsToys || 0);

    }
    if (objet === "All" || objet === "Achats Candy Bar") {
      totals.AchatsCandyBarL += Number(entry.AchatsCandyBar || 0);

      }
      if (objet === "All") {
        totals.Somme += Number(entry.TotalAmount || 0);
        totals.totalDailyExpenses += Number(entry.DailyExpenses || 0);
      }
    });
  
    totals.resultatnet = totals.Somme - totals.totalDailyExpenses;
  
    // Si un champ n'est pas sélectionné, laissez-le explicitement à 0
    if (objet !== "All" && objet !== "Loyers") totals.LoyersL = 0;
    if (objet !== "All" && objet !== "Banques") totals.BanquesL = 0;
    if (objet !== "All" && objet !== "Recettes Fiscales") totals.FiscL = 0;
    if (objet !== "All" && objet !== "Les Salaires") totals.SalaireL = 0;
    if (objet !== "All" && objet !== "STEG") totals.StegL = 0;
    if (objet !== "All" && objet !== "SONEDE") totals.SonedeL = 0;
    if (objet !== "All" && objet !== "Assurance") totals.AssuranceL = 0;
    if (objet !== "All" && objet !== "CNSS") totals.CnssL = 0;
    if (objet !== "All" && objet !== "Entretien Voitures") totals.EntretienL = 0;
    if (objet !== "All" && objet !== "Opérarteur Téléphoniques") totals.OperateurTelL = 0;
    if (objet !== "All" && objet !== "Opérateur Internet") totals.OperateurFibreL = 0;
    if (objet !== "All" && objet !== "Fournitures de Bureau") totals.FournituresBureauL = 0;
    if (objet !== "All" && objet !== "Achats Jouets") totals.AchatsToysL = 0;
    if (objet !== "All" && objet !== "Achats Candy Bar") totals.AchatsToysL = 0;
 
  
    return totals;
  };
  

  const filterData = () => {
    setRecherche(true);
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;

    const filtered = rapports.filter((entry) => {
      const entryDate = new Date(entry.DateCollect);
      const isLocationMatch = localisation === "All" || entry.localisation === localisation;
      const isObjetMatch = objet === "All" || entry.objet === objet;
      const isDateInRange = (!start || entryDate >= start) && (!end || entryDate <= end);

      return isLocationMatch && isObjetMatch && isDateInRange;
    });

    setFilteredData(filtered);
    setSomme(calcul(filtered));
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Rapport Résultats", 10, 10);
    doc.autoTable({
      startY: 20,
      head: [['#', 'Localisation', 'Objet', 'TotalAmount', 'Expenses', 'Net']],
      body: filteredData.map((el, i) => [i + 1, el.localisation, el.objet, el.TotalAmount, el.DailyExpenses, el.TotalAmount - el.DailyExpenses]),
    });
    doc.save('rapport_resultats.pdf');
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      filteredData.map((el, i) => ({
        "#": i + 1,
        Localisation: el.localisation,
        TotalAmount: el.TotalAmount,
        Expenses: el.DailyExpenses,
        Net: el.TotalAmount - el.DailyExpenses,
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Rapports");
    XLSX.writeFile(workbook, 'rapport_resultats.xlsx');
  };

  return (
    <div className="liste-rapports-twin">
      <h2>Recherche des charges d'exploitation</h2>
      <div className="filter-form">
        {/* Filtres */}
        <label>
          Localisation:
          <select value={localisation} onChange={(e) => setLocalisation(e.target.value)}>
            <option value="All">All</option>
            <option>Manar City</option>
            <option>Tunisia Mall</option>
            <option>Jardins d'El Menzah</option>
            <option>Jump Zone</option>
            <option>Entourage</option>
            <option>Art & Craft</option>
            <option>Administration</option>
          </select>
        </label>
        <label>
          Nature de Collecte:
          <select value={objet} onChange={(e) => setObjet(e.target.value)}>
            <option value="All">All</option>
            <option> Loyers</option>
            <option>Banques</option>
            <option>Recettes Fiscales</option>
            <option>Les Salaires</option>
            <option>STEG</option>
            <option>SONEDE</option>
            <option>CNSS</option>
            <option>Assurance</option>
            <option>Entretien Voitures</option>
            <option>Opérarteur Téléphoniques</option>
            <option>Opérateur Internet</option>
            <option>Fournitures de Bureau</option>
            <option>Achats Jouets</option>
            <option>Achats Candy Bar</option>
          </select>
        </label>
        <label>
          Start Date:
          <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        </label>
        <label>
          End Date:
          <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        </label>
        <button onClick={filterData}>Confirm</button>
      </div>

      {/* Résultats */}
      {recherche && (
        <div className="filtered-data">
          <h3>Résultats trouvés</h3>
          <div className="results-summary">
            <h3>Localisation : {localisation}</h3>
            <h3>Objet : {objet}</h3>
            <h3> Loyers : {somme.LoyersL}</h3>
            <h3>Banques : {somme.BanquesL}</h3>
            <h3>Recettes Fiscales : {somme.FiscL}</h3>
            <h3>Les Salaires : {somme.SalaireL}</h3>
            <h3>STEG : {somme.StegL}</h3>
            <h3>SONEDE : {somme.SonedeL}</h3>
            <h3>CNSS : {somme.CnssL}</h3>
            <h3>Assurance : {somme.AssuranceL}</h3>
            <h3>Entretien Voitures : {somme.EntretienL}</h3>
            <h3>Opérarteur Téléphoniques : {somme.OperateurTelL}</h3>
            <h3>Opérateur Internet : {somme.OperateurFibreL}</h3>
            <h3>Fournitures de Bureau : {somme.FournituresBureauL}</h3>
            <h3>Achats Jouets : {somme.AchatsToysL}</h3>
            <h3>Achats Candy Bar : {somme.AchatsCandyBarL}</h3>
            <h3>TotalAmount: {somme.Somme.toFixed(3)}</h3>
            <h3>Expenses: {somme.totalDailyExpenses.toFixed(3)}</h3>
            <h3>Net: {somme.resultatnet.toFixed(3)}</h3>
          </div>

          <Button variant="primary" onClick={downloadPDF} style={{ marginRight: "10px" }}>
            Télécharger en PDF
          </Button>
          <Button variant="success" onClick={downloadExcel}>
            Télécharger en Excel
          </Button>

          {filteredData?.length > 0 ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Rapport</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((el, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td><CardChargeExp el={el} /></td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>Aucun résultat trouvé</p>
          )}
        </div>
      )}
    </div>
  );
}

export default ListChargeExp;
