import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { current } from "../Redux/Actions/AuthActions";
import { getMySheets } from "../Redux/Actions/SheetActions";
import { getAllOvertimeRequests } from "../Redux/Actions/OvertimeActions";
import "./FichePaie.css";

const FichePaie = () => {
  const dispatch = useDispatch();
  const [dateNow, setDateNow] = useState({});
  const [calculatedSheets, setCalculatedSheets] = useState([]);
  const [overtimeData, setOvertimeData] = useState([]);

  const user = useSelector((state) => state.AuthReducer.user);
  const salaireMensuel = user?.salaire || 0;
  const mySheets = useSelector((state) => state.SheetReducer.mySheets || []);
  const overtimeRequests = useSelector((state) => state.OvertimeRequestReducer.overtimeRequests || []);

  // Charger les données utilisateur et les feuilles/HS associées
  useEffect(() => {
    dispatch(current());
    if (user?._id) {
      dispatch(getMySheets(user._id));
    }
    dispatch(getAllOvertimeRequests());
    setDateNow(getNow());
  }, [dispatch, user]);

  // Calcul des feuilles de temps et regroupement des heures supplémentaires
  useEffect(() => {
    if (mySheets.length > 0) {
      const updatedSheets = mySheets.map((sheet) => {
        const { workedHours, workedMinutes, delayHours, delayMinutes, penaltyDT } = calculateWorkTimeAndDelay(
          sheet.debut,
          sheet.entree,
          sheet.fin,
          sheet.sortie
        );

        return {
          ...sheet,
          workedHours,
          workedMinutes,
          delayHours,
          delayMinutes,
          penaltyDT,
          salaireJournalier: calculateDailyWage(workedHours, workedMinutes),
        };
      });

      setCalculatedSheets(updatedSheets);
    }

    // Filtrer et accumuler les heures supplémentaires de l'utilisateur connecté
    if (user?._id && overtimeRequests.length > 0) {
      const userSpecificRequests = overtimeRequests.filter(
        (request) => request.employeId === user._id // Filtrer les HS de l'utilisateur
      );
      if (userSpecificRequests.length > 0) {
        accumulateOvertimeByDate(userSpecificRequests);
      }
    }
  }, [mySheets, overtimeRequests, user]);

  const calculateOvertime = (startHour, endHour) => {
    const start = new Date(`2023-01-01T${startHour}:00`);
    const end = new Date(`2023-01-01T${endHour}:00`);
    const diff = (end - start) / 1000 / 60 / 60; // Différence en heures
    return diff > 0 ? diff : 0; // Retourne le nombre d'heures supplémentaires sans multiplier
  };

  const accumulateOvertimeByDate = (requests) => {
    const accumulated = {};

    requests.forEach((request) => {
      const date = new Date(request.date).toLocaleDateString();

      if (!accumulated[date]) {
        accumulated[date] = {
          totalOvertime: 0,
          totalAmount: 0,
        };
      }

      const overtime = calculateOvertime(request.startHour, request.endHour);
      const hourlyWage = salaireMensuel / 208; // Salaire horaire (208 heures par mois)
      const overtimeAmount = overtime * hourlyWage * 1.75; // Calcul du montant des heures supplémentaires avec coefficient 1.75

      accumulated[date].totalOvertime += overtime;
      accumulated[date].totalAmount += overtimeAmount;
    });

    setOvertimeData(Object.keys(accumulated).map((date) => ({
      date,
      totalOvertime: accumulated[date].totalOvertime.toFixed(2),
      totalAmount: accumulated[date].totalAmount.toFixed(2),
    })));
  };

  const getNow = () => {
    const currentTime = new Date();
    const hours = String(currentTime.getHours()).padStart(2, "0");
    const minutes = String(currentTime.getMinutes()).padStart(2, "0");
    const day = String(currentTime.getDate()).padStart(2, "0");
    const month = String(currentTime.getMonth() + 1).padStart(2, "0");
    const year = currentTime.getFullYear();

    return {
      formattedTime: `${hours}:${minutes}`,
      formattedDate: `${day}/${month}/${year}`,
    };
  };

  const calculateWorkTimeAndDelay = (debut, entree, fin, sortie) => {
    const parseTime = (time) => {
      if (!time) return null;
      const [hours, minutes] = time.split(":").map(Number);
      return hours * 60 + minutes;
    };

    const startTime = parseTime(debut);
    const entryTime = parseTime(entree);
    const endTime = parseTime(fin);
    const exitTime = parseTime(sortie);

    if (startTime === null || entryTime === null || endTime === null || exitTime === null) {
      return {
        workedHours: 0,
        workedMinutes: 0,
        delayHours: 0,
        delayMinutes: 0,
        penaltyDT: 0,
      };
    }
    
    const workedMinutes = Math.max(0, endTime - entryTime);
    const delayMinutes = Math.max(0, entryTime - startTime) + Math.max(0, endTime - exitTime);

    const hoursPerMonth = 208;
    const hourlyWage = salaireMensuel / hoursPerMonth;

    let penaltyDT = 0;
    if (delayMinutes > 15) {
      penaltyDT = delayMinutes > 60 ? 4 * hourlyWage : Math.ceil(delayMinutes / 15) * hourlyWage;
    }

    return {
      workedHours: Math.floor(workedMinutes / 60),
      workedMinutes: workedMinutes % 60,
      delayHours: Math.floor(delayMinutes / 60),
      delayMinutes: delayMinutes % 60,
      penaltyDT,
    };
  };

  const calculateDailyWage = (workedHours, workedMinutes) => {
    const hoursPerMonth = 208;
    const hourlyWage = salaireMensuel / hoursPerMonth;
    const totalWorkedHours = workedHours + workedMinutes / 60;
    return Math.round(totalWorkedHours * hourlyWage);
  };

  const calculateTotalWorkedHours = () => calculatedSheets.reduce((total, sheet) => {
    return total + sheet.workedHours + sheet.workedMinutes / 60;
  }, 0);

  const calculateTotalOvertime = () => overtimeData.reduce((total, overtime) => {
    return total + parseFloat(overtime.totalOvertime);
  }, 0);

  const calculateTotalOvertimeAmount = () => overtimeData.reduce((total, overtime) => {
    return total + parseFloat(overtime.totalAmount);
  }, 0);

  return (
    <div className="container">
      <div className="fiche-paie-header">
        <h3>Fiche de Paie</h3>
        <p>Nom de l'employé : <strong>{user?.name}</strong></p>
        <p>Salaire Mensuel : <strong>{salaireMensuel} DT</strong></p>
      </div>

      {calculatedSheets.length === 0 ? (
        <p>Aucune donnée disponible pour afficher la fiche de paie.</p>
      ) : (
        <>
          <h4>Emploi du Temps</h4>
          <table className="fiche-paie-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Heures Travaillées</th>
                <th>Retard (h:m)</th>
                <th>Pénalités (DT)</th>
                <th>Salaire Journalier (DT)</th>
              </tr>
            </thead>
            <tbody>
              {calculatedSheets.map((sheet, index) => (
                <tr key={index}>
                  <td>{sheet.date}</td>
                  <td>{sheet.workedHours}h {sheet.workedMinutes}m</td>
                  <td>{sheet.delayHours}h {sheet.delayMinutes}m</td>
                  <td>{sheet.penaltyDT?.toFixed(2)} DT</td>
                  <td>{sheet.salaireJournalier} DT</td>
                </tr>
              ))}
              <tr>
                <td><strong>Total</strong></td>
                <td><strong>{Math.floor(calculateTotalWorkedHours())}h {Math.round((calculateTotalWorkedHours() % 1) * 60)}m</strong></td>
                <td></td>
                <td></td>
                <td><strong>{Math.round(calculateTotalWorkedHours() * salaireMensuel / (208))} DT</strong></td>
              </tr>
            </tbody>
          </table>

          <h4>Heures Supplémentaires</h4>
          <table className="fiche-paie-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Heures Supplémentaires Approuvées</th>
                <th>Montant (DT)</th>
              </tr>
            </thead>
            <tbody>
              {overtimeData.map((overtime, index) => (
                <tr key={index}>
                  <td>{overtime.date}</td>
                  <td>{overtime.totalOvertime} h</td>
                  <td>{overtime.totalAmount} DT</td>
                </tr>
              ))}
              <tr>
                <td><strong>Total</strong></td>
                <td><strong>{calculateTotalOvertime()} h</strong></td>
                <td><strong>{calculateTotalOvertimeAmount().toFixed(2)} DT</strong></td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default FichePaie;
