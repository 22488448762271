import { GETHR, GETMYHR,UPDATE_HR_SUCCESS, DELETE_HR } from "../ActionTypes/HumainRessourcesTypes"
import axios from 'axios'

export const getHR= ()=>async(dispatch)=>{
    try {
       const res = await axios.get('/api/HumaRessources/getHR')
    
       dispatch(
    {
        type : GETHR,
        payload : res.data.HR
    }
    
       )
    
    } catch (error) {
        console.log(error)
    }
       
    }

    export const getMyHR= (id)=>async(dispatch)=>{
        try {
           const res = await axios.get(`/api/HumaRessources/GetHRbyOwner/${id}`)
        
           dispatch(
        {
            type : GETMYHR,
            payload : res.data.MYHR
        }
        
           )
        
        } catch (error) {
            console.log(error)
        }
           
        }

    export const addHR=(newHR)=>async(dispatch)=>{
    
        try {
            console.log(newHR)
           
            await axios.post('/api/HumaRessources/AddHR',newHR)
     
            dispatch(getHR())
     
           
        } catch (error) {
            console.log(error)
        }
     }

     export const updateHR = (id, updatedHR) => async (dispatch) => {
      try {
        
        const res = await axios.put(`/api/HumaRessources/UpdateHR/${id}`, updatedHR);
    
        dispatch({
          type: UPDATE_HR_SUCCESS,
          payload: res.data.updatedHR, // L'objet mis à jour retourné par l'API
        });
    
        // Mettre à jour la liste des emplois
        dispatch(getHR());
      } catch (error) {
        console.error(error);
      }
    };

    export const deleteHR = (id) => async (dispatch) => {
      try {
        await axios.delete(`/api/HumaRessources/DeleteHR/${id}`);
    
        dispatch({
          type: DELETE_HR,
          payload: id, // ID de l'emploi supprimé
        });
    
        // Mettre à jour la liste des emplois
        dispatch(getHR());
      } catch (error) {
        console.error(error);
      }
    };
    