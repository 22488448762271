import { GETCHARGEEXP } from "../ActionTypes/ChargeTypes"



const initialState = {
    ChargeExpl : []

}
const ChargeExpReducer=(state=initialState, action) =>{
switch (action.type) {
    case GETCHARGEEXP : return {...state, ChargeExpl : action.payload}

    default: return state
}
}

export default ChargeExpReducer;