import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { logout } from '../Redux/Actions/AuthActions';
import logo from "../image/flow.png";
import './NavAuth.css';  // Assurez-vous de créer ce fichier CSS pour le style de la barre latérale
import NotificationBadge from './NotificationBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const NavAuth = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    // Simule une mise à jour des notifications
    /*const fetchData = async () => {
      try {
        const response = await fetch('/api/notifications'); // URL de votre API
        const data = await response.json();
        
        // Calculer les notifications non lues
        const unreadCount = data.filter((notif) => !notif.read).length;
        dispatch({ type: 'SET_NOTIFICATION_COUNT', payload: unreadCount });
      } catch (error) {
        console.error('Erreur lors de la récupération des notifications:', error);
      }
    };

    fetchData();*/
  }, [dispatch]);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // État pour gérer la visibilité
  const [isLocked, setIsLocked] = useState(false); // Nouveau : Indique si la barre est verrouillée
  const token = localStorage.getItem('token');
  const user = useSelector(state => state.AuthReducer.user);
  
  const navigate = useNavigate();
  const notificationCount = useSelector((state) => state.notifications?.unreadCount || 0);


  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  
  return (
    <div className="nav-auth-container">
    {/* Bouton pour le menu hamburger */}
    <button className="hamburger-btn" onClick={toggleSidebar}>
      <FontAwesomeIcon icon={isSidebarOpen ? faTimes : faBars} />
    </button>

    {/* Barre latérale */}
    <div
      className={`sidebar ${isSidebarOpen ? "open" : "closed"}`}
      onMouseEnter={() => {
        if (!isLocked) setIsSidebarOpen(true); // Ouvrir au survol si non verrouillé
      }}
      onMouseLeave={() => {
        if (!isLocked) setIsSidebarOpen(false); // Fermer en quittant si non verrouillé
      }}
    >
    
      
  <div className="profile-section">
    <Link to="/UpdateProfil" className="profile-badge-link">
      <div className="profile-badge">
        <div className="badge-content">
        {token && user && (
          <span className="profile-initial">
            {user?.name?.charAt(0).toUpperCase()}
          </span>)}
          <span className="notification-icon">
            🔔
            {notificationCount > 0 && (
              <span className="notification-count">{notificationCount}</span>
            )}
          </span>
        </div>
      </div>
    </Link>
    <button
      className="logout-btn"
      onClick={() => {
        navigate('/Welcome');
        dispatch(logout());
      }}
    >
      Déconnexion
    </button>
  </div>

      <div className="menu">
        <ul>
          

          {token && user ? (
            <>
             {user.role == 'user' &&<li><Link to="/Accueil">Accueil</Link></li>}
             {user.role == 'admin' &&<li><Link to="/Home">Home</Link></li>}
             {user.role == 'admin' &&<li><Link to="/HomeGraph">HomeGraph</Link></li>}
             {user.role == 'admin' && <li ><Link to="/ApplicationFakhry">Dashboard</Link></li>}
              <li><Link to="/Profil">Profil</Link></li>

              <li>
                <div className="dropdown">
                  <button className="dropdown-btn">Demandes</button>
                  <div className="dropdown-content">
                   <Link to="/AddRequests">Faire une Demande</Link>
                  {user.role == 'admin' &&<Link to="/Requests">Suivi des demandes</Link>}
                  </div>
                </div>
              </li>

              <li>
                <div className="dropdown">
                  <button className="dropdown-btn">Wellness</button>
                  <div className="dropdown-content">
                  {(user.role == 'admin' || user.role == 'userwellness') &&  <Link to="/AddRapports">Rapports</Link>}
                  {user.role == 'admin' && <Link to="/ListRapport">Listes des Rapports</Link>}
                  </div>
                </div>
              </li>

              <li>
                <div className="dropdown">
                  <button className="dropdown-btn">Twin Park</button>
                  <div className="dropdown-content">
                  {(user.role == 'admin' || user.role == 'usertwinpark') && <Link to="/AddRapportsTwin">Rapports</Link>}
                    {(user.role == 'admin' || user.role == 'usertwinpark') && <Link to="/CollecteTwin">Collectes et Partenaires</Link>}
                    {user.role == 'admin' && <Link to="/ListeRapportTwin">Listes des Rapports</Link>}
                     {user.role == 'admin' && <Link to="/ListeCollecteTwin">Listes des Collectes</Link>}
                  </div>
                </div>
              </li>


              <li>
                <div className="dropdown">
                  <button className="dropdown-btn">Spintoss</button>
                  <div className="dropdown-content">

                  {(user.role == 'admin' || user.role == 'userspintoss') && <Link to="/AddRapportsSpintoss">Rapports</Link>}
                  {user.role == 'admin' && <Link to="/ListRapportsSpintoss">Listes des Rapports</Link>}
                    
                  </div>
                </div>
              </li>

              <li>
                <div className="dropdown">
                  <button className="dropdown-btn">Charges d'exploitation</button>
                  <div className="dropdown-content">

                  {user.role == 'admin' && <Link to="/AddChargeExp">Charges d'exploitation</Link>}
                  {user.role == 'admin' && <Link to="/ListChargeExp">Listes des Charges</Link>}
                    
                  </div>
                </div>
              </li>

              <li>
                <div className="dropdown">
                  <button className="dropdown-btn">Check-List</button>
                  <div className="dropdown-content">
                  {user.role == 'admin' && <Link to="/AddQuiz">Création Check-List</Link>}              
                  {(user.role == 'admin' || user.role == 'user') && <Link to="/MyQuizs">Mes Check-Lists</Link>}
                  {user.role == 'admin' && <Link to="/QuizGroupedResponse/${el._id}">Check-List Reçus</Link>}
                    {/* <Link to="/CLManarCity">Manar City</Link> */}
                  </div>
                </div>
              </li>

              <li>
                <div className="dropdown">
                  <button className="dropdown-btn">HR</button>
                  <div className="dropdown-content">
                  {user.role == 'admin' && <Link to="/HumanResources">Saisie des Emplois</Link>}
                    <Link to="/MyEmploi">Mon Emploi</Link>
                    {user.role == 'admin' && <Link to="/HumanRessourcesList">Les Emplois</Link>}
                     <Link to="/GestionHeuresSupp">Gestion des Heures Supp</Link>
                    {user.role == 'admin' &&<Link to="/GestionHR">Consultation HR</Link>}
                    {user.role == 'admin' &&<Link to="/GestionAllHR">Consultation Heures supp</Link>}
                    <Link to="/FichePaie">Fiche de Paie</Link>
                    
                  </div>
                </div>
              </li>
              
              {user.role == 'admin' &&<li><Link to="/AllUser">Utilisateurs</Link></li>}
              {user.role == 'admin' &&<li><Link to="/Register">Register</Link></li>}
            </>
          ) : (
            <>
              <li><Link to="/Welcome">Welcome to TASKO</Link></li>
              <li><Link to="/Login">Login</Link></li>
              
             
            </>
          )}
        </ul>
      </div>
    </div>
    </div>
  );
};

export default NavAuth;
