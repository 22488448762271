import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import './CollecteTwin.css' ; // Feuille CSS pour styles
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {AddChargeExp} from '../Redux/Actions/ChargeExpActions'


const ChargeExp = () => {
  const [emplacement, setEmplacement] = useState('');
  const [objet, setObjet] = useState('');
  const [date] = useState(new Date().toISOString().split('T')[0]);
  const [amount, setAmount] = useState(0);
  const [expense, setExpense] = useState(0);
  const [explanation, setExplanation] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleRapport = (e) => {
    e.preventDefault();

    // Validation des champs
    if (!emplacement || !objet ||  !date || amount <= 0 || expense < 0 || !explanation) {
      alert("Veuillez remplir tous les champs correctement.");
      return;
    }


    // Données à envoyer
    const rapportData = {


      DateCollect : date.slice(0,10),
      localisation : emplacement,
      objet : objet,
      TotalAmount : parseFloat(amount),
      DailyExpenses: parseFloat(expense),
      feedback :explanation,

    Loyers : objet == "Loyers" ? amount : 0,
    Banques : objet == "Banques" ? amount : 0,
    Fisc : objet == "Recettes Fiscales" ? amount : 0,
    Salaire : objet == "Les Salaires" ? amount : 0,
    Steg : objet == "STEG" ? amount : 0,
    Sonede : objet == "SONEDE" ? amount : 0,
    Cnss : objet == "CNSS" ? amount : 0, 
    Assurance : objet == "Assurance" ? amount : 0, 
    Entretien : objet == "Entretien Voitures" ? amount : 0,
    OperateurTel : objet == "Opérarteur Téléphoniques" ? amount : 0,
    OperateurFibre : objet == "Opérateur Internet" ? amount : 0,
    FournituresBureau : objet == "Fournitures de Bureau" ? amount : 0, 
    AchatsToys : objet == "Achats Jouets" ? amount : 0, 
    AchatsCandyBar : objet == "Achats Candy Bar" ? amount : 0, 
   
    };

    

    dispatch(AddChargeExp(rapportData,navigate))

   
  };

  return (

    <div className="list-rapports">   
      <h3>Charges d'exploitation du jour</h3>

    <Form className="rapport-form">
      
        <Form.Group as={Col} controlId="formGridState" className="rapport-form-group">
          <Form.Label>Emplacement</Form.Label>
          <Form.Select 
            onChange={(e) => setEmplacement(e.target.value)} 
            className="rapport-select" 
            defaultValue="Choisissez un emplacement...">
            
            
            <option disabled>Choisissez un emplacement...</option>
            <option>Manar City</option>
            <option>Tunisia Mall</option>
            <option>Jardins d'El Menzah</option>
            <option>Jump Zone</option>
            <option>Entourage</option>
            <option>Art & Craft</option>
            <option>Administration</option>
            
         
          </Form.Select>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridState" className="rapport-form-group">
          <Form.Label>Objet</Form.Label>
          <Form.Select 
            onChange={(e) => setObjet(e.target.value)} 
            className="rapport-select" 
            defaultValue="Types de Charges...">

            <option disabled>Types de Charges...</option>

    
            <option> Loyers</option>
            <option>Banques</option>
            <option>Recettes Fiscales</option>
            <option>Les Salaires</option>
            <option>STEG</option>
            <option>SONEDE</option>
            <option>CNSS</option>
            <option>Assurance</option>
            <option>Entretien Voitures</option>
            <option>Opérarteur Téléphoniques</option>
            <option>Opérateur Internet</option>
            <option>Fournitures de Bureau</option>
            <option>Achats Jouets</option>
            <option>Achats Candy Bar</option>


            </Form.Select>
        </Form.Group>
      
      <Row>
      <Form.Group as={Col} controlId="formGridDate" className="rapport-form-group">
      <Form.Label>Date du jour</Form.Label>
      <Form.Control 
        type="date" 
        className="rapport-input" 
        value={date} 
        readOnly // Rend le champ non modifiable
      />
    </Form.Group>

        <Form.Group as={Col} controlId="formGridAmount" className="rapport-form-group">
          <Form.Label>Montant</Form.Label>
          <Form.Control 
            onChange={(e) => setAmount(e.target.value)} 
            type="number" 
            className="rapport-input" 
            placeholder="Entrer le total Collect" 
            min="0"
          />
        </Form.Group>
      </Row>

      <Form.Group controlId="formGridExpenses" className="rapport-form-group">
        <Form.Label>Dépenses effectuées</Form.Label>
        <Form.Control 
          onChange={(e) => setExpense(e.target.value)} 
          type="number" 
          className="rapport-input" 
          min="0"
        />
      </Form.Group>

      <Form.Group controlId="explanationTextarea" className="rapport-form-group">
        <Form.Label>Explication des dépenses</Form.Label>
        <Form.Control 
          onChange={(e) => setExplanation(e.target.value)} 
          as="textarea" 
          rows={3} 
          className="rapport-textarea" 
        />
      </Form.Group>

      <Form.Group controlId="formGridCheckbox" className="mb-3">
        <Form.Check 
          type="checkbox" 
          label="Confirmer vos données" 
          required
        />
      </Form.Group>

      <Button onClick={(e) => handleRapport(e)} variant="primary" type="submit" className="rapport-button" >
        Soumettre
      </Button>
    </Form>
    </div>
  );
};

export default ChargeExp;
