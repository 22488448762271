import { CURRENT, FAIL, GETALLUSERS, GETONEUSER, LOGIN, LOGOUT, REGISTER } from "../ActionTypes/AuthTypes"

const initialState = {
user : {}, 
role : '',
errors : [],
Users : [],
upUser : {}


}

const AuthReducer=(state = initialState,action)=>{
switch (action.type) {

    case REGISTER : 
    localStorage.setItem('token', action.payload.token)
    return {...state, user : action.payload.newAccount,role: action.payload.newAccount.role, errors: []}
   
    case LOGIN : 
    localStorage.setItem('token', action.payload.token)
    return {...state,user : action.payload.found, role: action.payload.found.role, errors : []}

    case LOGOUT : 
    localStorage.removeItem('token')
    return {...state, user : {}, role: '', errors: []}

    case CURRENT : return{...state, user : action.payload, role: action.payload.role}

    case FAIL : return{...state, errors : action.payload}

    case GETALLUSERS : return {...state, Users : action.payload}

    case GETONEUSER : return {...state, upUser : action.payload }

    default: return state
}

}

export default AuthReducer