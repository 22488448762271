import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getrapportspintoss } from "../Redux/Actions/RapportSpintossAction";
import CardRapportSpintoss from "./CardRapportSpintoss";
import './ListRapportsTwin.css';
import { Table, Button } from 'react-bootstrap';
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";



function ListRapportsSpintoss() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getrapportspintoss());
  }, [dispatch]);

  const rapports = useSelector((state) => state.RapportSpintossReducer.RapportsSpintoss) 

  const calcul = (data) => {
    return {
      totalAmount: data.reduce((acc, cur) => acc + (cur.TotalAmount || 0), 0),
      totalDailyExpenses: data.reduce((acc, cur) => acc + (cur.DailyExpenses || 0), 0),
      resultatnet: data.reduce((acc, cur) => acc + ((cur.TotalAmount || 0) - (cur.DailyExpenses || 0)), 0),
      Espece: data.reduce((acc, cur) => acc + (cur.Espece || 0), 0),
      Reduction10: data.reduce((acc, cur) => acc + (cur.Reduction10 || 0), 0),
      Reduction20: data.reduce((acc, cur) => acc + (cur.Reduction20 || 0), 0),
      ReductionTwin: data.reduce((acc, cur) => acc + (cur.ReductionTwin || 0), 0),
      Privilege: data.reduce((acc, cur) => acc + (cur.Privilege || 0), 0),
      Glovo: data.reduce((acc, cur) => acc + (cur.Glovo || 0), 0),
      Tresto: data.reduce((acc, cur) => acc + (cur.Tresto || 0), 0),
      Tpe: data.reduce((acc, cur) => acc + (cur.Tpe || 0), 0),
      Cheque: data.reduce((acc, cur) => acc + (cur.Cheque || 0), 0),
      packAnnif: data.reduce((acc, cur) => acc + (cur.PackAnnif || 0), 0),
    };
  };

  const [somme, setSomme] = useState({
    totalAmount: 0,
    totalDailyExpenses: 0,
    resultatnet: 0,
    Espece: 0,
    Reduction10: 0,
    Reduction20: 0,
    ReductionTwin: 0,
    Privilege: 0,
    Glovo: 0,
    Tresto: 0,
    Tpe: 0,
    Cheque: 0,
    packAnnif: 0,
  });

  const [localisation, setLocalisation] = useState("All");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [recherche,setRecherche] = useState(false)

  const filterData = () => {
    setRecherche(true)
    if (!Array.isArray(rapports) || rapports.length === 0) {
      setFilteredData([]);
      return;
    }

    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;

    const filtered = rapports.filter((entry) => {
      const entryDate = new Date(entry.DateCollect);
      const isLocationMatch = localisation === "All" || entry.localisation === localisation;
      const isDateInRange = (!start || entryDate >= start) && (!end || entryDate <= end);
      return isLocationMatch && isDateInRange;
    });
console.log(filtered)
    setFilteredData(filtered);
    setSomme(calcul(filtered));
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
  
    // En-tête
    doc.text("Rapport de Résultats", 20, 10);
  
    // Ajout des données du résumé dans le tableau
    const data = [
      ["Localisation", localisation],
      ["Espéces", somme.Espece],
      ["Reduction 10%", somme.Reduction10],
      ["Réduction 20%", somme.Reduction20],
      ["Réduction TwinClub", somme.ReductionTwin],
      ["Privilége", somme.Privilege],
      ["Glovo", somme.Glovo],
      ["Ticket Resto", somme.Tresto],
      ["Tpe", somme.Tpe],
      ["Chèque", somme.Cheque],
      ["Pack Anniversaire", somme.packAnnif],
      ["Amount", somme.totalAmount],
      ["Expenses", somme.totalDailyExpenses],
      ["Net", somme.resultatnet],
    ];
  
    // Génération du tableau PDF
    doc.autoTable({
      head: [["Catégorie", "Valeur"]],
      body: data,
    });
  
    doc.save("rapport_resultats.pdf");
  };


const downloadExcel = () => {
  const wsData = [
    ["Catégorie", "Valeur"],
    ["Localisation", localisation],
    ["Espéces", somme.Espece],
    ["Reduction 10%", somme.Reduction10],
    ["Réduction 20%", somme.Reduction20],
    ["Réduction TwinClub", somme.ReductionTwin],
    ["Privilége", somme.Privilege],
    ["Glovo", somme.Glovo],
    ["Ticket Resto", somme.Tresto],
    ["Tpe", somme.Tpe],
    ["Chèque", somme.Cheque],
    ["Pack Anniversaire", somme.packAnnif],
    ["Amount", somme.totalAmount],
    ["Expenses", somme.totalDailyExpenses],
    ["Net", somme.resultatnet],
  ];

  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet(wsData);
  XLSX.utils.book_append_sheet(wb, ws, "Rapport de Résultats");

  XLSX.writeFile(wb, "rapport_resultats.xlsx");
};


  return (
    <div className="liste-rapports-twin">
      <h2>Recherche de Rapport Spintoss</h2>
      <div className="filter-form">
        <label>
          Localisation:
          <select value={localisation} onChange={(e) => setLocalisation(e.target.value)}>
            <option value="All">All</option>
            <option>Spintoss- Manar City</option>
            <option>Spintoss - Tunisia Mall</option>
            <option>Spintoss - Jardins d'eL Menzah</option>
            <option>Spintoss - Mall of Djerba</option>
          </select>
        </label>

        <label>
          Start Date:
          <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        </label>

        <label>
          End Date:
          <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        </label>

        <button onClick={filterData}>Confirm</button>
      </div>
    {
      recherche &&
      <div className="filtered-data">
        <h3>Résultats trouvés</h3>
        <div className="results-summary">
          <h3>Localisation : {localisation}</h3>
          <h3>Espéces: {somme.Espece}</h3>
          <h3>Reduction 10%: {somme.Reduction10}</h3>
          <h3>Réduction 20%: {somme.Reduction20}</h3>
          <h3>Réduction TwinClub: {somme.ReductionTwin}</h3>
          <h3>Privilége: {somme.Privilege}</h3>
          <h3>Glovo: {somme.Glovo}</h3>
          <h3>Ticket Resto: {somme.Tresto}</h3>
          <h3>Tpe: {somme.Tpe}</h3>
          <h3>Chèque: {somme.Cheque}</h3>
          <h3>Pack Anniversaire: {somme.packAnnif}</h3>
          <h3>Amount: {somme.totalAmount.toFixed(3)}</h3>
          <h3>Expenses: {somme.totalDailyExpenses.toFixed(3)}</h3>
          <h3>Net: {somme.resultatnet.toFixed(3)}</h3>
        </div>

        <Button variant="primary" onClick={downloadPDF} style={{ marginRight: "10px" }}>
          Télécharger en PDF
        </Button>
        <Button variant="success" onClick={downloadExcel}>
          Télécharger en Excel
        </Button>

        {filteredData.length > 0 ? (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Rapport</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((el, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td><CardRapportSpintoss el={el} /></td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p>Aucun résultat trouvé</p>
        )}
      </div>
    }
    </div>

    // <div>
      
    //   <div>
    //     {
    //       rapports.map((el,i,t)=> <CardRapportSpintoss el={el}/>)
    //     }
    //   </div>
    // </div>
  );
}

export default ListRapportsSpintoss;
