import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import ErrorsReducer from "./ErrorsReducer";
import ReducersRequest from "./ReducersRequest";
import RapportReducer from "./RapportReducer";
import RapportTwinReducer from "./RapportTwinReducer";
import HumanRessourcesReducer from "./HumainRessourcesReducer";
import notificationsReducer from "./notificationsReducer";
import SheetReducer from "./SheetReducer";
import QuizReducer from "./QuizReducer";
import OvertimeRequestReducer from "./OvertimeRequestReducer";
import RapportSpintossReducer from "./RapportSpintossReducer";
import ChargeExpReducer from "./ChargeExpReducer";




const rootReducer = combineReducers({
    AuthReducer,
    ErrorsReducer,
    ReducersRequest,
    RapportReducer,
    RapportTwinReducer,
    HumanRessourcesReducer,
    SheetReducer,
    QuizReducer,
    OvertimeRequestReducer,
    RapportSpintossReducer,
    ChargeExpReducer,
    notifications: notificationsReducer, // Assurez-vous que cela correspond à la clé utilisée
});

export default rootReducer;
