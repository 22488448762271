import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { register } from '../Redux/Actions/AuthActions';
import Container from 'react-bootstrap/Container';
import './Register.css';
import logo from "../image/flow.png";

const Register = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [matricule, setMatricule] = useState('');
    const [localisation,setLocalisation] = useState('');
  const [salaire, setSalaire] = useState('');
  const [adress, setAdress] = useState('')
  const [phone, setPhone] = useState('')
  const [cin, setCin] = useState('')
  const [dateembauche, setDateembauche] = useState('')
  const [fonction,setFonction] = useState('')
  const [cnss, setCnss] =useState('')
  const [role, setRole] = useState('user');


   const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleRegister = (e) => {
    e.preventDefault();
    dispatch(register({ name, email, password, matricule, localisation,salaire,adress,phone, cin, dateembauche,fonction,cnss,role}, navigate));
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center vh-100 register-background">
      <div className="register-container shadow rounded p-5 text-center">
        <img src={logo} alt="Mon logo" className="mb-4" style={{ width: '180px' }} />
  
        <Form onSubmit={handleRegister}>
  <div className="row">
    <div className="col-md-4">
      <Form.Group className="mb-3">
        <Form.Label>Nom</Form.Label>
        <Form.Control
          onChange={(e) => setName(e.target.value)}
          type="text"
          placeholder="Votre nom"
          className="input-field"
          value={name}
        />
      </Form.Group>
    </div>

    <div className="col-md-4">
      <Form.Group className="mb-3">
        <Form.Label>Matricule</Form.Label>
        <Form.Control 
          onChange={(e) => setMatricule(e.target.value)}
          type="text"
          placeholder="Matricule"
          className="input-field"
          value={matricule}
        />
      </Form.Group>
    </div>

    <div className="col-md-4">
      <Form.Group className="mb-3">
        <Form.Label>Adresse Email</Form.Label>
        <Form.Control
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          placeholder="Votre email"
          className="input-field"
          value={email}
        />
      </Form.Group>
    </div>
  </div>

  <div className="row">
    <div className="col-md-4">
      <Form.Group className="mb-3">
        <Form.Label>Mot de passe</Form.Label>
        <Form.Control
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          placeholder="Votre mot de passe"
          className="input-field"
          value={password}
        />
      </Form.Group>
    </div>

    <div className="col-md-4">
              <Form.Group className="mb-3">
                <Form.Label>Rôle</Form.Label>
                <Form.Select
                  onChange={(e) => setRole(e.target.value)}
                  value={role}
                  className="input-field"
                >
                  <option value="user">Utilisateur de base</option>
                  <option value="admin">Administrateur</option>
                  <option value="userspintoss">Spintoss</option>
                  <option value="usertwinpark">Twin Park</option>
                  <option value="userwellness">Wellness</option>
                </Form.Select>
              </Form.Group>
            </div>

    <div className="col-md-4">
      <Form.Group className="mb-3">
        <Form.Label>Fonction</Form.Label>
        <Form.Control
          onChange={(e) => setFonction(e.target.value)}
          type="text"
          placeholder="Fonction occupée"
          className="input-field"
          value={fonction}
        />
      </Form.Group>
    </div>

    <div className="col-md-4">
      <Form.Group className="mb-3">
        <Form.Label>N° d'affiliation CNSS</Form.Label>
        <Form.Control
          onChange={(e) => setCnss(e.target.value)}
          type="text"
          placeholder="Numéro d'affiliation"
          className="input-field"
          value={cnss}
        />
      </Form.Group>
    </div>
  </div>

  <div className="row">
    <div className="col-md-4">
      <Form.Group className="mb-4">
        <Form.Label>Localisation</Form.Label>
        <Form.Control
          onChange={(e) => setLocalisation(e.target.value)}
          type="text"
          placeholder="Votre localisation"
          className="input-field"
          value={localisation}
        />
      </Form.Group>
    </div>

    <div className="col-md-4">
      <Form.Group className="mb-4">
        <Form.Label>Salaire</Form.Label>
        <Form.Control
          onChange={(e) => setSalaire(e.target.value)}
          type="text"
          placeholder="Saisir le salaire"
          className="input-field"
          value={salaire}
        />
      </Form.Group>
    </div>

    <div className="col-md-4">
      <Form.Group className="mb-4">
        <Form.Label>Adresse</Form.Label>
        <Form.Control
          onChange={(e) => setAdress(e.target.value)}
          type="text"
          placeholder="Saisir adresse"
          className="input-field"
          value={adress}
        />
      </Form.Group>
    </div>
  </div>

  <div className="row">
    <div className="col-md-4">
      <Form.Group className="mb-4">
        <Form.Label>Numéro de téléphone</Form.Label>
        <Form.Control
          onChange={(e) => setPhone(e.target.value)}
          type="text"
          placeholder="Saisir numéro"
          className="input-field"
          value={phone}
        />
      </Form.Group>
    </div>

    <div className="col-md-4">
      <Form.Group className="mb-4">
        <Form.Label>CIN</Form.Label>
        <Form.Control
          onChange={(e) => setCin(e.target.value)}
          type="text"
          placeholder="Saisir CIN"
          className="input-field"
          value={cin}
        />
      </Form.Group>
    </div>

    <div className="col-md-4">
      <Form.Group className="mb-4">
        <Form.Label>Date d'embauche</Form.Label>
        <Form.Control
          onChange={(e) => setDateembauche(e.target.value)}
          type="text"
          placeholder="Saisir date"
          className="input-field"
          value={dateembauche}
        />
      </Form.Group>
    </div>
  </div>

    
          <Button onClick={(e) => handleRegister(e)} variant="primary" type="submit">
            Register
          </Button>
        </Form>
      </div>
    </div>
  );
}  

export default Register;
